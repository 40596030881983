
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { gsap } from 'gsap';
import reactionAPI from '../api/reactions';
import filesAPI from '../api/files';
import hasCurrentFirmUuid from '../mixins/hasCurrentFirmUuid';
import filesize from 'filesize';

export default {
  name: 'MessageSingle',

  mixins: [hasCurrentFirmUuid],

  props: {
    message: {},
    reactions: [],
    conversationUuid: null,
    groupedTogether: false,
  },

  data() {
    return {
      availableReactions: [
        {
          uuid: '1r',
          type: 'like',
        },
        {
          uuid: '2r',
          type: 'love',
        },
        {
          uuid: '3r',
          type: 'laughing',
        },
        {
          uuid: '4r',
          type: 'surprised',
        },
      ],
      showReaction: false,
      userHasReacted: false,
    };
  },

  computed: {
    ...mapGetters('user', ['currentAccountUuid']),
    ...mapState('user', ['user']),

    isCurrentUser() {
      return this.message.author.uuid === this.currentAccountUuid;
    },

    hideAuthor() {
      return !this.groupedTogether;
    },
  },

  mounted() {
    this.q = gsap.utils.selector(this.$el);

    this.$nextTick(() => {
      if (this.isCurrentUser) {
        gsap.set(this.q('.reaction-select-element'), { x: 200 });
      } else {
        gsap.set(this.q('.reaction-select-element'), { x: -200 });
      }
    });
  },

  methods: {
    isOnline(a) {
      // need to add a check to see if user is online

      return true;
    },

    checkIfOnline() {
      return this.isOnline ? 'bg-p-green' : 'bg-p-gray';
    },

    messageOver() {
      gsap.killTweensOf(this.q('.reaction-select-element'));

      if (this.isCurrentUser) {
        gsap.to(this.q('.reaction-select-element'), {
          x: 0,
          stagger: 0.1,
        });
      } else {
        gsap.to(this.q('.reaction-select-element'), {
          x: 0,
          stagger: -0.1,
        });
      }
    },

    messageLeave() {
      gsap.killTweensOf(this.q('.reaction-select-element'));

      if (this.isCurrentUser) {
        gsap.to(this.q('.reaction-select-element'), {
          x: 250,
          stagger: -0.1,
          delay: 0.25,
        });
      } else {
        gsap.to(this.q('.reaction-select-element'), {
          x: -250,
          stagger: 0.1,
          delay: 0.25,
        });
      }
    },

    addReaction(o) {
      let index = this.message.reactions.findIndex((item) => item.uuid === o.event.uuid);

      let u = {};

      u.uuid = this.user.uuid;
      u.name = this.user.first_name + ' ' + this.user.first_name;

      this.message.reactions[index].users.push(u);
    },

    reactionClicked(event) {
      let reactionUUID = null;
      this.message.reactions.forEach((element) => {
        if (element.reaction === event.type && element.account_uuid === this.currentAccountUuid) {
          reactionUUID = element.uuid;
        }
      });

      if (reactionUUID) {
        reactionAPI.remove(reactionUUID).then((rsp) => {
          this.message.reactions.splice(
            this.message.reactions.findIndex((e) => e.uuid === reactionUUID),
            1
          );
        });
      } else {
        let data = {
          reaction: event.type.toLowerCase(),
          account_uuid: this.currentAccountUuid,
          reactable_type: 'message',
          reactable_uuid: this.message.uuid,
        };
        // send to the server
        reactionAPI.react(data).then((rsp) => {
          if (this.message.reactions) {
            this.message.reactions.push(rsp.data.data);
          } else {
            this.message.reactions = [rsp.data.data];
          }
        });
      }
    },

    getSize(size) {
      return filesize(size);
    },

    canDownloadFile(file) {
      return file.status === 'uploaded';
    },

    cannotDownloadFile(file) {
      return !this.canDownloadFile(file);
    },

    downloadFile(file) {
      if (this.cannotDownloadFile(file)) {
        return;
      }

      filesAPI.downloadFile(file);
    },
  },
};
