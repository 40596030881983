import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      modelType: '',
      customFields: [],
      customFieldValues: [],
    };
  },

  created() {
    this.grabCustomFields();
  },

  computed: {
    ...mapGetters('customFields', ['getCustomFieldByModelType']),
  },

  methods: {
    buildDataBlob(original, clientData) {
      const clientCustomFields = clientData && clientData.custom_fields ? clientData.custom_fields : [];

      if (this.customFields.length > 0) {
        if (original.custom_fields === undefined) {
          original.custom_fields = {};
          this.customFields.forEach((item) => {
            let field = clientCustomFields.find((field) => field.reference === item.reference);
            original.custom_fields[item.reference] = field ? field.value : null;
          });
        } else {
          // working with existing stuff.
          let newObject = {};
          this.customFields.forEach((item) => {
            let field = clientCustomFields.find((field) => field.reference === item.reference);
            newObject[item.reference] = field ? field.value : null;
          });
          original.custom_fields = newObject;
        }
      }
      return original;
    },

    grabCustomFields() {
      this.customFields = this.getCustomFieldByModelType(this.modelType);
    },
  },
};
