import { render, staticRenderFns } from "./ConversationInboxList.vue?vue&type=template&id=8ef466d8"
import script from "./ConversationInboxList.vue?vue&type=script&lang=js"
export * from "./ConversationInboxList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConversationsModalInboxItem: require('/vercel/path0/components/conversations/ModalInboxItem.vue').default,Pagination: require('/vercel/path0/components/Pagination.vue').default})
