import defaultFoldersAPI from '@/api/defaultFolders';

export default {
  namespaced: true,

  state: () => ({
    model: null,
    editingFolder: null,
    updatedFolder: null,
  }),

  actions: {
    async getFolderStructure({ commit }, id) {
      try {
        const response = await defaultFoldersAPI.getModel(id);
        commit('SET_MODEL', response.data.data);
      } catch (error) {
        console.error('Failed to get folder structure:', error);
      }
    },

    setEditingFolder({ commit }, folder) {
      commit('SET_EDITING_FOLDER', folder);
    },

    setModel({ commit }, model) {
      commit('SET_MODEL', model);
    },

    async updateFolderStructure({ commit }, model) {
      if (model?.uuid) {
        await defaultFoldersAPI.updateModel(model, model.uuid);
        commit('SET_EDITING_FOLDER', null);
        commit('SET_UPDATED_FOLDER', null);
      }
    },

    async archiveFolder({ commit }, { model, router }) {
      try {
        await defaultFoldersAPI.updateModel(
          {
            model: model.model,
            parameter: model.parameter,
            structure: model.structure,
            is_active: false,
          },
          model.uuid
        );
        router.push('/settings/default-folders');
      } catch (error) {
        console.error('Failed to archive folder:', error);
      }
    },

    async unarchiveFolder({ commit }, { model, router }) {
      try {
        await defaultFoldersAPI.updateModel(
          {
            model: model.model,
            parameter: model.parameter,
            structure: model.structure,
            is_active: true,
          },
          model.uuid
        );
        router.push('/settings/default-folders');
      } catch (error) {
        console.error('Failed to unarchive folder:', error);
      }
    },

    async removeFolder({ commit }, { model, router }) {
      try {
        await defaultFoldersAPI.deleteModel(model.uuid);
        router.push('/settings/default-folders');
      } catch (error) {
        console.error('Failed to remove folder:', error);
      }
    },
  },

  mutations: {
    SET_MODEL(state, model) {
      state.model = model;
    },
    SET_EDITING_FOLDER(state, folder) {
      state.editingFolder = folder;
    },
    SET_UPDATED_FOLDER(state, folder) {
      state.updatedFolder = folder;
    },
  },
};
