import paginator from "@/helpers/paginator";

export default {

  // Monthly Statements

  getAllStatements: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/statement', data),
  getStatement: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/statement/' + uuid),
  lockStatement: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/statement/' + uuid + '/lock'),
  unlockStatement: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/statement/' + uuid + '/unlock'),

  // Charges (Schedules)

  createCharge: (data) => $nuxt.$axios.post($nuxt.$api.host + '/charge', data),
  getCharge: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/charge/' + uuid),
  getCharges: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/charge', data),
  getClientCharges: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + uuid + '/charges', data),
  getPlanCharges: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/plans/' + uuid + '/charges', data),
  updateCharge: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/charge/' + uuid, data),
  deleteCharge: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/charge/' + uuid),

  // Expectations

  createExpectation: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expectation', data),
  getExpectation: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/expectation/' + uuid),
  getClientExpectations: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + uuid + '/expectations', data),
  getPlanExpectations: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/plans/' + uuid + '/expectations', data),
  getExpectations: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/expectation', data),
  updateExpectation: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/expectation/' + uuid, data),
  deleteExpectation: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/expectation/' + uuid),
  reconcileExpectation: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expectation/reconcile', data),
  calculate: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expectation/calculate', data),
  balanceExpectations: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expectation/balance', data),
  unbalanceExpectation: (data) => $nuxt.$axios.post($nuxt.$api.host + '/expectation/unbalance', data),

  // Provider Statements

  createProviderStatement: (data) => $nuxt.$axios.post($nuxt.$api.host + '/provider-statement', data),
  getProviderStatements: (data) => $nuxt.$axios.get($nuxt.$api.host + '/provider-statement', data),
  getProviderStatement: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/provider-statement/' + uuid, data),
  updateProviderStatement: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/provider-statement/' + uuid, data),
  deleteProviderStatement: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/provider-statement/' + uuid),
  uploadProviderStatement: (data) => $nuxt.$axios.post($nuxt.$api.host + '/provider-statement/upload', data),

  // Provider Statement Transactions

  getProviderStatementTransactions: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/statement-transaction', data),
  createProviderStatementTransaction: (data) => $nuxt.$axios.post($nuxt.$api.host + '/statement-transaction', data),
  getProviderStatementTransaction: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/statement-transaction/' + uuid),
  updateProviderStatementTransaction: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/statement-transaction/' + uuid, data),
  deleteProviderStatementTransaction: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/statement-transaction/' + uuid),
  reconcileProviderStatementTransaction: (uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/statement-transaction/' + uuid + '/reconcile', data),
  unreconcileExpectationFromTransaction: (uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/statement-transaction/' + uuid + '/unreconcile-expectation', data),
  bulkCreateExpectations: (data) => $nuxt.$axios.post($nuxt.$api.host + '/statement-transaction/create-expectations', data),
  autoReconcile: (data) => $nuxt.$axios.post($nuxt.$api.host + '/statement-transaction/auto-reconcile', data),

  // Bank Statements

  createBankStatement: (data) => $nuxt.$axios.post($nuxt.$api.host + '/bank-statement', data),
  getBankStatements: (data) => $nuxt.$axios.get($nuxt.$api.host + '/bank-statement', data),
  getBankStatement: (uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/bank-statement/' + uuid, data),
  updateBankStatement: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/bank-statement/' + uuid, data),
  deleteBankStatement: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/bank-statement/' + uuid),
  uploadBankStatement: (data) => $nuxt.$axios.post($nuxt.$api.host + '/bank-statement/upload', data),

  // Bank Transactions

  getBankTransactions: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/bank-transaction', data),
  createBankTransaction: (data) => $nuxt.$axios.post($nuxt.$api.host + '/bank-transaction', data),
  getBankTransaction: (uuid) => $nuxt.$axios.get($nuxt.$api.host + '/bank-transaction/' + uuid),
  updateBankTransaction: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/bank-transaction/' + uuid, data),
  deleteBankTransaction: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/bank-transaction/' + uuid),
  reconcileBankTransaction: (data) => $nuxt.$axios.post($nuxt.$api.host + '/bank-transaction/reconcile', data),

  // Reconciliations

  deleteReconciliation: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/reconciliation/' + uuid),
}
