import incomeReconciliationAPI from '@/api/incomeReconciliation';
import staticDataAPI from '@/api/static';

export default {
  namespace: true,

  state: () => ({
    currentStatement: null,
    categoryOptions: [],
  }),

  getters: {
    currentStatementUuid: (state) => {
      return state.currentStatement?.uuid;
    },
    statementStart: (state) => {
      return state.currentStatement?.start_date;
    },
    statementEnd: (state) => {
      return state.currentStatement?.end_date;
    },
  },

  mutations: {
    SET_STATEMENT(state, statement) {
      state.currentStatement = statement;
    },
    RESET_STATEMENT(state) {
      state.currentStatement = null;
    },
    SET_CHARGE_CATEGORIES(state, categories) {
      state.categoryOptions = categories;
    },
  },

  actions: {
    getStatement({ commit }, uuid) {
      return incomeReconciliationAPI
        .getStatement(uuid)
        .then((rsp) => {
          commit('SET_STATEMENT', rsp.data.data);
        })
        .catch((err) => {
          throw err;
        });
    },

    resetStatement({ commit }) {
      commit('RESET_STATEMENT');
    },

    getChargeCategories({ state, commit }) {
      if (state.categoryOptions.length > 0) {
        return;
      }

      return staticDataAPI
        .getChargeCategories()
        .then((rsp) => {
          commit('SET_CHARGE_CATEGORIES', rsp.data.data);
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
