
import { mapGetters } from 'vuex';

export default {
  name: 'ModalInboxItem',

  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      conversations: [],
    };
  },

  computed: {
    ...mapGetters('user', ['isClient', 'isEmployee']),
  },
};
