export default {
  state: () => ({
    customFields: [],
  }),

  getters: {
    getCustomFieldByModelType: (state) => (type) => {
      return state.customFields.filter((field) => field.model_type === type);
    },
  },

  actions: {
    //
  },

  mutations: {
    SET_CUSTOM_FIELDS(state, customFields) {
      state.customFields = customFields;
    },
  },

  namespaced: true,
};
