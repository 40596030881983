
export default {
  name: 'widget_scale',
  props: {
    percentageLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    scale_colour: {
      type: String,
      default: 'bg-p-pink',
      required: false,
    },
    scale_bg_colour: {
      type: String,
      default: 'bg-p-pink-pale',
      required: false,
    },
    text_colour: {
      type: String,
      default: 'text-p-pink',
      required: false,
    },
    duration: {
      type: Number,
      default: 2,
      required: false,
    },
    ease: {
      type: String,
      default: 'sine.inOut',
      required: false,
    },
    amount: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: 'h-2.5',
    },
    popOver: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      percentage: '',
      percentage_target: '',
      percentageVisible: false,
      percentageRound: '',
    };
  },
  mounted() {
    let toolTip = this.$refs.tooltip;
    this.$gsap.set(toolTip, { scaleX: 0, scaleY: 0, transformOrigin: '50% 100%' });
    this.animateToAmount();
    this.percentageRound = Math.round(this.percentage)
  },
  methods: {
    animateToAmount() {
      let totalWidth = this.$refs.outer.clientWidth;
      this.percentage_target = (this.amount / this.total) * totalWidth;
      this.percentage = (this.amount / this.total) * 100;
      this.percentage = Math.round(this.percentage * 100) / 100;
      let animation_target = this.percentage + '%';
      this.percentageRound = Math.round(this.percentage)
      this.$gsap.to(this.$refs.scale, { width: animation_target, duration: this.duration, ease: this.ease });
    },

    showPercentage() {
      let toolTip = this.$refs.tooltip;
      this.$gsap.killTweensOf(toolTip);
      this.percentageVisible = true;
      this.$gsap.to(toolTip, { scaleX: 1, scaleY: 1, transformOrigin: '50% 100%', duration: 0.25 });
    },

    hidePercentage() {
      let toolTip = this.$refs.tooltip;
      this.$gsap.killTweensOf(toolTip);
      this.$gsap.to(toolTip, {
        scaleX: 0,
        scaleY: 0,
        transformOrigin: '50% 100%',
        duration: 0.25,
        onComplete: function () {
          this.percentageVisible = false;
        },
      });
    },
  },
  watch: {
    amount(amount) {
      this.animateToAmount();
    },
  },
};
