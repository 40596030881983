export default {
  allForModel: (model, uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/time-entry/' + model + '/' + uuid, data),
  all: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/time-entry', data),
  create: (data) => $nuxt.$axios.post($nuxt.$api.host + '/time-entry', data),
  get: (timer_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/time-entry/' + timer_uuid),
  update: (timer_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/time-entry/' + timer_uuid, data),
  remove: (timer_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/time-entry/' + timer_uuid),
  stopTimer: (timer_uuid) => $nuxt.$axios.post($nuxt.$api.host + '/time-entry/' + timer_uuid + '/stop'),
  allForClient: (client_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/all-time-entries', data),
};
