import paginator from '../helpers/paginator';

export default {
  getAllNotifications: async () => await paginator.grabAllPages($nuxt.$api.host + '/notifications'),
  getNotifications: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/notifications', data),
  getNotification: (notification_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/notifications/' + notification_uuid),
  markAsRead: (notification) => $nuxt.$axios.post($nuxt.$api.host + '/notifications/read/' + notification),
  markAsUnread: (notification) => $nuxt.$axios.post($nuxt.$api.host + '/notifications/unread/' + notification),
  markAllAsRead: () => $nuxt.$axios.post($nuxt.$api.host + '/notifications/read-all/'),
  deleteNotification: (notification) => $nuxt.$axios.delete($nuxt.$api.host + '/notifications/' + notification),
};
