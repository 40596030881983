import mergeWith from 'lodash.mergewith';
export default {
  customizer(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      try {
        return objValue.concat(srcValue);
      } catch(e) {

      }
    }
  },

  async grabAllPages(url, data, params, per_page = 200) {

    data = data || {};
    await $nuxt.$axios.get(url,  {params: {...params, per_page}}).then(response => {
      mergeWith(data, response.data, this.customizer);
      if (response.data.links.next != null) {
        return this.grabAllPages(response.data.links.next, data, params);
      }
    });

    return data.data;
  }

}
