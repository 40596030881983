import paginator from "../helpers/paginator";
export default {

    // Conversations
    createConversation: (data) => $nuxt.$axios.post($nuxt.$api.host + '/conversation', data),
    getConversation: (conversation_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/conversation/' + conversation_uuid, data),
    updateConversation: (conversation_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/conversation/' + conversation_uuid, data),
    archiveConversation: (conversation_uuid) => $nuxt.$axios.post($nuxt.$api.host + '/conversation/' + conversation_uuid + '/archive'),
    unarchiveConversation: (conversation_uuid) => $nuxt.$axios.post($nuxt.$api.host + '/conversation/' + conversation_uuid + '/unarchive'),
    removeConversation: (conversation_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/conversation/' + conversation_uuid),
    updatePin: (conversation_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/conversation/' + conversation_uuid + '/pin', data),

    getAccountConversations: (account_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/account/' + account_uuid + '/conversations', data),
    getAllConversationsForAccount: async (account_uuid, data = null) => await paginator.grabAllPages($nuxt.$api.host + '/account/' + account_uuid + '/conversations', {}, data),
    getFirmConversations: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/conversation', data),
    getAllConversationsForFirm: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/conversation', {}, data),

    // Participants

    addParticipants: (conversation_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/conversation/' + conversation_uuid + '/participants', data),
    removeParticipants: (conversation_uuid, data) => $nuxt.$axios.delete($nuxt.$api.host + '/conversation/' + conversation_uuid + '/participants', data),

    // Messages

    createMessage: (conversation_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/conversation/' + conversation_uuid + '/messages', data),
    getMessages: (conversation_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/conversation/' + conversation_uuid + '/messages', data),
    getMessage: (conversation_uuid, message_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/conversation/' + conversation_uuid + '/messages/' + message_uuid),
    getAllMessages: async (conversation_uuid, data = null) => await paginator.grabAllPages($nuxt.$api.host + '/conversation/' + conversation_uuid + '/messages', data),

}
