
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'NavigationNavLogo',
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('user', ['user', 'currentLogin']),
    ...mapGetters('user', {
      getFirmLogo: 'getFirmLogo',
      getFirmIcon: 'getFirmIcon',
    }),
  },
  data: () => {
    return {
      firm: {
        logo: null,
        name: null,
      },
      showIcon: false,
    };
  },
  created() {
    this.getFirmDetails();
  },
  methods: {
    getFirmDetails() {
      this.firm.logo = this.user.current_account.firm.logo;
      this.firm.name = this.user.current_account.firm.name;
    },
  },
};
