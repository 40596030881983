
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NotificationModal',

  computed: {
    ...mapState('notification', ['notifications', 'meta', 'notificationPopUpClose', 'include_read']),
    ...mapState('user', ['user']),
    ...mapState('localStorage', ['notificationPreference']),

    openNotificationModal() {
      if (this.notificationPopUpClose) {
        return true;
      }
    },

    include_seen: {
      get() {
        return this.include_read;
      },
      set(value) {
        this.setIncludeSeen(value);
      },
    },
  },

  created() {
    this.refreshNotifications();
  },

  mounted() {
    this.$nuxt.$on('OpenNotificationModal', this.open);
    this.$nuxt.$on('notificationClicked', this.redirectItem);
  },

  beforeDestroy() {
    this.$nuxt.$off('OpenNotificationModal');
    this.$nuxt.$off('notificationClicked');
  },

  methods: {
    ...mapActions('localStorage', ['setNotificationPreference']),
    ...mapActions('notification', [
      'refreshNotifications',
      'markAllAsRead',
      'markAsRead',
      'redirectTo',
      'changePage',
      'modalClose',
      'modalOpen',
      'toggleIncludeRead',
    ]),

    setIncludeSeen(v) {
      this.toggleIncludeRead(v);
    },

    markAllNotificationsAsRead() {
      this.markAllAsRead();
      this.close();
    },

    changePages(url) {
      this.changePage(url);
    },

    open() {
      this.modalOpen();
      this.$refs.openNotifications.open();
    },

    close() {
      this.$refs.openNotifications.close();
    },

    redirectItem(notification) {
      this.$emit('clicked');
      this.markAsRead(notification);
      this.redirectTo(notification);
    },
  },

  watch: {
    openNotificationModal: {
      deep: true,
      handler(newVal) {
        if (newVal === true) {
          this.close();
        }
      },
    },
  },
};
