import accountAPI from '@/api/account';
import checkFor404 from '../helpers/checkFor404';

export default {
  namespaced: true,

  state: () => ({
    client: null,
  }),

  getters: {
    isClientExternal(state) {
      return state.client?.type === 'external';
    },
  },

  actions: {
    setActiveClient({ commit }, client) {
      commit('SET_CLIENT', client);
    },

    async refreshClient({ commit }, { client_uuid }) {
      await accountAPI
        .getContact(client_uuid)
        .then((rsp) => {
          commit('SET_CLIENT', rsp.data.data);
        })
        .catch((err) => {
          checkFor404.process(err);
        });
    },
  },

  mutations: {
    SET_CLIENT(state, client) {
      state.client = client;
    },

    CLEAR_CLIENT(state) {
      state.client = null;
    },
  },
};
