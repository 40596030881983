import Vue from 'vue';
import { DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor';
import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor';

import { DropDownButtonPlugin } from '@syncfusion/ej2-vue-splitbuttons';
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
import { ListViewPlugin } from '@syncfusion/ej2-vue-lists';
import { registerLicense, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';

export default function (context) {
  Vue.use(RichTextEditorPlugin);
  Vue.use(DocumentEditorContainerPlugin);
  Vue.use(DropDownButtonPlugin);
  Vue.use(ButtonPlugin);
  Vue.use(DialogPlugin);
  Vue.use(ListViewPlugin);

  setCulture('en-GB');
  setCurrencyCode('GBP');

  registerLicense(context.$config.syncFusionKey);
}
