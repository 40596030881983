import { render, staticRenderFns } from "./simplified.vue?vue&type=template&id=952cb4c4"
import script from "./simplified.vue?vue&type=script&lang=js"
export * from "./simplified.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNotificationEventListener: require('/vercel/path0/components/AppNotificationEventListener.vue').default,BrandingColoursCSS: require('/vercel/path0/components/BrandingColoursCSS.vue').default,NavigationSidebarMobile: require('/vercel/path0/components/navigation/SidebarMobile.vue').default,NavigationSidebar: require('/vercel/path0/components/navigation/Sidebar.vue').default,FileUploader: require('/vercel/path0/components/FileUploader.vue').default,SearchBlock: require('/vercel/path0/components/SearchBlock.vue').default,Notification: require('/vercel/path0/components/Notification.vue').default,ConfirmDialog: require('/vercel/path0/components/ConfirmDialog.vue').default,RocketBar: require('/vercel/path0/components/RocketBar.vue').default,ModalsCreateNote: require('/vercel/path0/components/modals/create-note.vue').default,ModalsCreateTask: require('/vercel/path0/components/modals/CreateTask.vue').default,ModalsConversationModal: require('/vercel/path0/components/modals/conversation-modal.vue').default,NotificationsNotificationModal: require('/vercel/path0/components/notifications/notificationModal.vue').default,ModalsWelcomeWidget: require('/vercel/path0/components/modals/WelcomeWidget.vue').default,ModalsTimerControl: require('/vercel/path0/components/modals/timer-control.vue').default,SessionExpiry: require('/vercel/path0/components/SessionExpiry.vue').default,CrispChat: require('/vercel/path0/components/CrispChat.vue').default})
