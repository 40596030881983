import Vue from 'vue';
import VueShortKey from 'vue-shortkey';

Vue.use(VueShortKey, {
  prevent: [
    '.v-short-ignore',
    '#modal-link-url',
    '.redactor-box',
    '.redactor-styles',
    '.arx-container',
    '.arx-form-input',
    '.arx-editor-frame',
    '.arx-form-textarea',
    '#crisp-chatbox',
    '.cc-c5c3',
    '.rex-form-input',
  ],
});
