
import { Timer } from 'easytimer.js';
import { mapState } from 'vuex';
import timeEntryAPI from '@/api/timeEntries';
import checkForErrors from '@/helpers/checkForErrors';

export default {
  name: 'timer-modal',
  props: {
    straightThru: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      timerHolder: null,
      localTimer: '',
      recordType: 'timer',
      recordOptions: [
        {
          value: 'timer',
          label: 'Start timer',
        },
        {
          value: 'add',
          label: 'Add a block',
        },
      ],
      extTimer: null,
      timerExists: false,
      isWorking: false,
      elapsed: null,
      timerEntries: [],
      hours: null,
      minutes: 0,
      form: {
        name: null,
        start_date: null,
        client_uuid: null,
        case_uuid: null,
        task_uuid: null,
        total_time_in_minutes: null,
      },
      currentTimer: {
        uuid: null,
      },
      hasErrors: false,
      chosenClient: null,
      chosenCaseUuid: null,
      chosenPlanUuid: null,
      chosenTaskUuid: null,
      taskType: 'client',
      taskTypeOptions: [
        {
          label: 'Client',
          value: 'client',
        },
        {
          label: 'Case',
          value: 'case',
        },
        {
          label: 'Plan',
          value: 'plan',
        },
      ],
      targetType: null,
      targetOptions: [
        {
          label: 'Firm',
          value: 'firm',
        },
        {
          label: 'Client',
          value: 'client',
        },
        {
          label: 'Case',
          value: 'case',
        },
        {
          label: 'Task',
          value: 'task',
        },
      ],
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('client', ['client']),
    ...mapState('cases', ['singleCase']),
    ...mapState('plan', ['plan']),

    isClientPage() {
      return this.$route.fullPath.includes('contacts');
    },
    isTaskPage() {
      return this.$route.fullPath.includes('tasks') && this.$route.params.tasksid;
    },
    isCasePage() {
      return this.$route.fullPath.includes('cases');
    },
    isPlanPage() {
      return this.$route.fullPath.includes('plans');
    },
    timerRunning() {
      return this.user.current_account.current_time_entry !== null;
    },

    taskAccountSelectorLabel() {
      if (this.taskType === 'client') {
        return 'Select a client to filter tasks.';
      } else if (this.taskType === 'case') {
        return 'Select a client to filter cases.';
      } else if (this.taskType === 'plan') {
        return 'Select a client to filter plans.';
      }
    },

    target() {
      if (this.targetType === 'firm') {
        return {
          trackable_type: 'firm',
          trackable_uuid: this.user.current_account.firm.uuid,
        };
      }
      if (this.targetType === 'client') {
        return {
          trackable_type: 'client',
          trackable_uuid: this.chosenClient.uuid,
        };
      }
      if (this.targetType === 'case') {
        return {
          trackable_type: 'case',
          trackable_uuid: this.chosenCaseUuid,
        };
      }
      if (this.targetType === 'task') {
        return {
          trackable_type: 'task',
          trackable_uuid: this.chosenTaskUuid,
        };
      }
    },
  },

  mounted() {
    if (this.user.current_account.current_time_entry) {
      this.startLocalTimer(this.user.current_account.current_time_entry);
    }
    this.$nuxt.$on('timerStopped', this.stopTimer);
    this.$nuxt.$on('timerStarted', this.startLocalTimer);
    this.$nuxt.$on('timerFinished', this.close);
    this.$nuxt.$on('timerRecordedRefresh', this.close);

    this.checkForRunningTimer();
  },

  beforeDestroy() {
    this.$nuxt.$off('timerStopped');
    this.$nuxt.$off('timerStarted');
    this.$nuxt.$off('timerFinished');
    this.$nuxt.$off('timerRecordedRefresh');
  },

  methods: {
    open() {
      this.setTargetTypeByPage();
      this.$refs.timetracking.open();
    },

    close() {
      this.$refs.timetracking.close();
    },

    setTargetTypeByPage() {
      if (!this.timerRunning) {
        if (this.isTaskPage) {
          this.targetType = 'task';
          this.chosenTaskUuid = this.$route.params.tasksid;

          if (this.isCasePage) {
            this.taskType = 'case';
            this.chosenCaseUuid = this.$route.params.id;
          } else if (this.isPlanPage) {
            this.taskType = 'plan';
            this.chosenClient = this.plan?.client;
            this.chosenPlanUuid = this.plan?.uuid;
          } else {
            this.taskType = 'client';
            this.chosenClient = this.client;
          }
        } else if (this.isCasePage) {
          this.targetType = 'case';
          this.chosenCaseUuid = this.$route.params.id;
        } else if (this.isClientPage) {
          this.targetType = 'client';
          this.chosenClient = this.client;
        } else {
          this.targetType = 'firm';
        }
      }
    },

    startLocalTimer(timer) {
      this.timerHolder = new Timer();
      this.timerHolder.start({
        precision: 'seconds',
        startValues: {
          seconds: this.$dayjs().diff(timer.start_date, 'seconds'),
        },
      });
      this.timerHolder.addEventListener('secondsUpdated', (e) => {
        this.localTimer = this.timerHolder.getTimeValues().toString();
      });
    },

    stopTimer() {
      this.timerHolder.stop();
      this.localTimer = null;
      this.$nuxt.$authService.refreshState();
      this.timerHolder.removeEventListener('secondsUpdated');
    },

    addTimerEntry() {
      this.isWorking = true;
      const errors = this.validateForm();
      if (errors.length) {
        this.setErrors(errors);
        return;
      }
      const startDate = this.$dayjs(this.form.start_date ?? undefined)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');

      const londonDate = this.$nuxt.$helpers.convertToLondonDateTime(startDate);
      let data = {
        ...this.target,
        name: this.form.name,
        total_time_in_minutes: this.form.total_time_in_minutes,
        start_date: londonDate,
      };

      timeEntryAPI
        .create(data)
        .then((rsp) => {
          this.form = {
            name: null,
            start_date: null,
            client_uuid: null,
            case_uuid: null,
            task_uuid: null,
            total_time_in_minutes: null,
          };
          this.$nuxt.$emit('notification', 'Time recorded');
          if (this.isClientPage) {
            this.$nuxt.$emit('timerRecordedRefresh');
          } else {
            this.$nuxt.$emit('timerFinished');
          }
          this.close();
        })
        .catch((err) => {
          checkForErrors.process(err, this.$refs.formErrorPanel);
        })
        .finally(() => {
          this.isWorking = false;
        });
    },

    checkForRunningTimer() {
      if (this.user.current_account.current_time_entry) {
        this.currentTimer = this.user.current_account.current_time_entry;
        this.form.name = this.currentTimer.name;
      }
    },

    stopTimer() {
      this.isWorking = true;
      timeEntryAPI
        .stopTimer(this.user.current_account.current_time_entry.uuid)
        .then((rsp) => {
          this.form = {
            name: null,
            client_uuid: null,
            case_uuid: null,
            task_uuid: null,
            total_time_in_minutes: null,
          };
          this.$nuxt.$authService.refreshState();
          this.$nuxt.$emit('notification', 'Timer Stopped');
          if (this.isClientPage) {
            this.$nuxt.$emit('timerRecordedRefresh');
          } else {
            this.$nuxt.$emit('timerFinished');
          }
        })
        .catch((err) => {
          checkForErrors.process(err, this.$refs.formErrorPanel);
        })
        .finally(() => {
          this.isWorking = false;
        });
    },

    startTimerEntry() {
      this.isWorking = true;
      this.hasErrors = false;
      const errors = this.validateForm();
      if (errors.length) {
        this.setErrors(errors);
        return;
      }

      const now = new Date();

      let data = {
        ...this.target,
        name: this.form.name,
        start_date: this.$nuxt.$helpers.convertToLondonDateTime(now),
      };
      timeEntryAPI
        .create(data)
        .then((rsp) => {
          this.$nuxt.$authService.refreshState();
          this.$nuxt.$emit('timerStarted', rsp.data.data);
          this.$nuxt.$emit('notification', 'New timer started');
        })
        .catch((err) => {
          this.hasErrors = true;
          checkForErrors.process(err, this.$refs.formErrorPanel);
        })
        .finally(() => {
          this.isWorking = false;
        });
    },

    setErrors(errsArray) {
      this.hasErrors = true;
      this.$refs.formErrorPanel.setErrors(errsArray);
    },

    validateForm() {
      const errors = [];

      if (this.targetType === 'client') {
        // must have a chosen client
        if (!this.chosenClient) {
          errors.push({ client: 'A client must be selected' });
        }
      }

      if (this.targetType === 'case') {
        // must have a chosen case
        if (!this.chosenCaseUuid) {
          errors.push({ client: 'A case must be selected' });
        }
      }

      if (this.targetType === 'task') {
        // must have a chosen task
        if (!this.chosenTaskUuid) {
          errors.push({ client: 'A task must be selected' });
        }
      }

      return errors;
    },
  },
};
