
import BrandingColoursCSS from '../components/BrandingColoursCSS';

export default {
  name: 'App',

  components: { BrandingColoursCSS },

  data() {
    return {
      menuOpen: false,
      showAccountBlock: false,
      showOverlay: false,
    };
  },

  watch: {
    $route(to, from) {
      this.closeMenu();
    },
  },
};
