
import { mapState } from 'vuex';
import welcomeWidgetAPI from '@/api/welcomeWidget';

export default {
  name: 'NewWelcomeWidget',

  computed: {
    ...mapState('user', ['user']),

    completedWidgets() {
      if (this.allWidgets) {
        return this.allWidgets.filter((item) => {
          return item.is_complete === true;
        });
      }
    },
    incompleteWidgets() {
      if (this.allWidgets) {
        return this.allWidgets.filter((item) => {
          return item.is_complete === false;
        });
      }
    },
  },

  data() {
    return {
      allWidgets: [],
    };
  },

  created() {
    this.getWidgets();
  },

  methods: {
    downloadGuide() {
      window.open(this.$nuxt.$api.laravelURL + '/static-files/welcome-file');
    },

    open() {
      this.$refs.welcomeWidgetModal.open();
    },
    close() {
      this.$refs.welcomeWidgetModal.close();
    },
    getWidgets() {
      welcomeWidgetAPI.get().then((rsp) => {
        this.allWidgets = rsp.data.data;
      });
    },
    saveWelcomeWidget(widget) {
      welcomeWidgetAPI.update(widget.id, { is_complete: widget.is_complete }).then((rsp) => {
        this.allWidgets = rsp.data.data;
      });
    },
  },
};
