
export default {
  name: 'widgetItem',

  props: {
    widget: {
      type: Object,
      required: true,
    },
    contentHidden: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      collapsed: false,
    };
  },

  mounted() {
    if (!this.widget.is_complete) {
      this.collapsed = false;
      this.$refs.panel.open();
    }
  },

  methods: {
    collapse() {
      if (this.collapsed) {
        this.$refs.panel.open();
      } else {
        this.$refs.panel.close();
      }
      this.collapsed = !this.collapsed;
    },
    updateCheckbox(widget) {
      widget.is_complete = !widget.is_complete;
      this.$emit('saveWidgets', widget);
    },
    openGuide(widget) {
      window.open(widget.resource_url, '_blank');
    },
  },
};
