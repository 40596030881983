import Vue from 'vue';
import {
    camelCase, // `camelCase`
    pascalCase, // `PascalCase`
    capitalCase, // `Capital Case`
    headerCase, // `Header-Case`
    titleCase, // `Title Case`
    pathCase, // `path/case`
    snakeCase, // `snake_case`
    paramCase, // `param-case`
    dotCase, // `dot.case`
    noCase, // `no case`
    constantCase, // `CONSTANT_CASE`
    lowerCase, // `lower case`
    lowerCaseFirst, // `lOWER CASE FIRST`
    upperCase, // `UPPER CASE`
    upperCaseFirst, // `Upper case first`
    swapCase, // `sWaP cAsE` -> `SwAp CaSe`
    sentenceCase,
    isLowerCase,
    isUpperCase,
  } from "text-case";

export default () => {
  Vue.filter('camelCase', function (value) {
    if (!value) return '';
    return camelCase(value);
  });
  Vue.filter('pascalCase', function (value) {
    if (!value) return '';
    return pascalCase(value);
  });
  Vue.filter('capitalCase', function (value) {
    if (!value) return '';
    return capitalCase(value);
  });
  Vue.filter('headerCase', function (value) {
    if (!value) return '';
    return headerCase(value);
  });
  Vue.filter('titleCase', function (value) {
    if (!value) return '';
    return titleCase(value);
  });
  Vue.filter('pathCase', function (value) {
    if (!value) return '';
    return pathCase(value);
  });
  Vue.filter('snakeCase', function (value) {
    if (!value) return '';
    return snakeCase(value);
  });
  Vue.filter('paramCase', function (value) {
    if (!value) return '';
    return paramCase(value);
  });
  Vue.filter('dotCase', function (value) {
    if (!value) return '';
    return dotCase(value);
  });
  Vue.filter('noCase', function (value) {
    if (!value) return '';
    return noCase(value);
  });
  Vue.filter('constantCase', function (value) {
    if (!value) return '';
    return constantCase(value);
  });
  Vue.filter('lowerCase', function (value) {
    if (!value) return '';
    return lowerCase(value);
  });
  Vue.filter('lowerCaseFirst', function (value) {
    if (!value) return '';
    return lowerCaseFirst(value);
  });
  Vue.filter('upperCase', function (value) {
    if (!value) return '';
    return upperCase(value);
  });
  Vue.filter('upperCaseFirst', function (value) {
    if (!value) return '';
    return upperCaseFirst(value);
  });
  Vue.filter('swapCase', function (value) {
    if (!value) return '';
    return swapCase(value);
  });
};
