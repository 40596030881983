import plansAPI from '@/api/plans';
import checkFor404 from '@/helpers/checkFor404';

export default {
  namespaced: true,

  state: () => ({
    plan: {
      name: null,
      sub_accounts: [],
      type: '',
      sub_type: '',
      main_account: {
        policy_number: null,
      },
      wrap: null,
      tags: [],
      client: {},
    },
    loaded: false,
    contributions: [],
    withdrawals: [],
    origin: 'client',
  }),

  getters: {
    getPlanFromState(state) {
      return state.plan;
    },
  },

  actions: {
    setPlan({ commit }, plan) {
      commit('SET_PLAN', plan);
    },

    setLoaded({ commit }, value) {
      commit('SET_LOADED', value);
    },

    setContributions({ commit }, value) {
      commit('SET_CONTRIBUTIONS', value);
    },

    setWithdrawals({ commit }, value) {
      commit('SET_WITHDRAWALS', value);
    },

    refreshPlan({ dispatch, commit }, { plan_uuid }) {
      plansAPI.get(plan_uuid).then((rsp) => {
        commit('SET_PLAN', rsp.data.data);
        commit('SET_LOADED', true);
        return rsp.data;
      }).catch((err) => {
        checkFor404.process(err);
      });
    },
  },

  mutations: {
    SET_PLAN(state, plan) {
      state.plan = plan;
    },
    SET_LOADED(state, value) {
      state.loaded = value;
    },
    SET_CONTRIBUTIONS(state, value) {
      state.contributions = value;
    },
    SET_WITHDRAWALS(state, value) {
      state.withdrawals = value;
    },
    RESET_ORIGIN(state) {
      state.origin = 'client';
    },
    SET_ORIGIN(state, payload) {
      state.origin = payload;
    },
  },
};
