import paginator from '~/helpers/paginator';

export default {
  getMySettings: () => $nuxt.$axios.get($nuxt.$api.host + '/me'),
  updateMySettings: (data) => $nuxt.$axios.put($nuxt.$api.host + '/me', data),

  getUnipassCert: () => $nuxt.$axios.get($nuxt.$api.host + '/unipass/certificate'),
  storeUnipassCert: (data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/unipass/certificate', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  removeUnipassCert: () => $nuxt.$axios.delete($nuxt.$api.host + '/unipass/certificate'),
};
