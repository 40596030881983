import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('user', ['currentAccount']),
  },

  methods: {
    async setStickyData(key, filters) {
      await this.$localForage.setItem(this.currentAccount.uuid + '-' + key, filters);
    },

    async getStickyData(key) {
      return await this.$localForage.getItem(this.currentAccount.uuid + '-' + key);
    },

    async removeData(key) {
      await this.$localForage.removeItem(this.currentAccount.uuid + '-' + key)
    },
  },
};
