export default {
  getOccupations: () => $nuxt.$axios.get($nuxt.$api.host + '/static/occupations'),
  getNationalities: () => $nuxt.$axios.get($nuxt.$api.host + '/static/nationalities'),
  getAssetTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/plan-types`),
  getAssetSubTypes: (asset_type) => $nuxt.$axios.get(`${$nuxt.$api.host}/static/plan-sub-types/${asset_type}`),
  getAllTransactionTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/transaction-types`),
  getTransactionSubType: (transaction_type) =>
    $nuxt.$axios.get(`${$nuxt.$api.host}/static/transaction-sub-types/${transaction_type}`),
  getChargeCategories: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/charge-categories`),
  getRMARTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/rmar-types`),
  getServiceTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/service-types`),
  getModelableTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/logs-modelable-types`),
  getContributionTypes: (type) => $nuxt.$axios.get(`${$nuxt.$api.host}/static/contribution-types/${type}`),
  getAdviceTypes: (type) => $nuxt.$axios.get(`${$nuxt.$api.host}/static/advice-types/${type}`),
  getConductedBy: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/conducted-by`),
  getSourceOfFunds: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/source-of-funds`),
  getFrequencies: (type = null) => $nuxt.$axios.get(`${$nuxt.$api.host}/static/frequencies/${type}`),
  getNBEStatuses: (type = null) => $nuxt.$axios.get(`${$nuxt.$api.host}/static/new-business-events-statuses`),
  getDepartments: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/departments`),
  getBooleanChoices: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/boolean-choices`),
  getInterestTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/interest-types`),
  getPropertyTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/property-types`),
  getRepaymentTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/repayment-types`),
  getRepaymentMethods: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/repayment-methods`),
  getTermTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/term-types`),
  getTransferTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/transfer-types`),
  getPlanInactiveReasons: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/plan-inactivity-reasons`),
  getPlanStatuses: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/plan-statuses`),
  getPlanCoverTypes: (type) => $nuxt.$axios.get(`${$nuxt.$api.host}/static/cover-types/${type}`),
  getExtensionTypes: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/extensions`),
  getReports: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/reports`),
  getCrystallisationReasons: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/crystallisation-reasons`),
  getRegulatoryClassifications: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/client-regulatory-classifications`),
  getSourceOfPremiums: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/source-of-premiums`),
  getPaymentMethods: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/premium-payment-methods`),
  getStatesOfHealth: () => $nuxt.$axios.get(`${$nuxt.$api.host}/static/state-of-health`),
};
