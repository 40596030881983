import paginator from '@/helpers/paginator';

export default {
  getAll: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/workflow', data),
  getTasksFlattened: (workflow_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task/flattened'),
  getFullTree: (workflow_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/workflow/' + workflow_uuid + '/tree'),
  getAllPaginated: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/workflow'),
  create: (data) => $nuxt.$axios.post($nuxt.$api.host + '/workflow', data),
  get: (workflow_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/workflow/' + workflow_uuid, data),
  update: (workflow_uuid, data = null) => $nuxt.$axios.put($nuxt.$api.host + '/workflow/' + workflow_uuid, data),
  delete: (workflow_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/workflow/' + workflow_uuid),
  getAllTasks: async (workflow_uuid, data = null) =>
    await paginator.grabAllPages($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task', [], data),
  createTask: (workflow_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task', data),
  getTask: (workflow_uuid, task_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task/' + task_uuid),
  updateTask: (workflow_uuid, task_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task/' + task_uuid, data),
  deleteTask: (workflow_uuid, task_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task/' + task_uuid),
  addParticipantToTask: (workflow_uuid, task_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task/' + task_uuid + '/participants', data),
  removeParticipantFromTask: (workflow_uuid, task_uuid, data) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/workflow/' + workflow_uuid + '/task/' + task_uuid + '/participants', data),
  triggerWorkflow: (workflow_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/workflow/' + workflow_uuid + '/run', data),
  getTree: (workflow_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/workflow/' + workflow_uuid + '/tree'),
  cloneModel: (model_type, model_uuid) =>
    $nuxt.$axios.post($nuxt.$api.host + '/clone/' + model_type + '/' + model_uuid),
};
