export default {
  namespaced: true,
  state: () => ({
    mobile: true,
  }),
  getters: {
    isMobile: (state) => {
      return state.mobile;
    },
  },
  actions: {
    checkDeviceSize({ dispatch }) {
      window.onresize = null;

      dispatch('checkScreenWidth');

      window.onresize = reportWindowSize;

      function reportWindowSize() {
        dispatch('checkScreenWidth');
      }
    },

    clearResizeEvent({ dispatch }) {
      window.onresize = null;
    },

    checkScreenWidth({ commit, dispatch }) {
      if (window.innerWidth < 768) {
        commit('SET_DISPLAY_TYPE_MOBILE', true);
      } else {
        commit('SET_DISPLAY_TYPE_MOBILE', false);
      }
    },
  },

  mutations: {
    SET_DISPLAY_TYPE_MOBILE(state, data) {
      state.mobile = data;
    },
  },
};
