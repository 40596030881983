import accountAPI from '~/api/account';

export default ({ app }, inject) => {
  inject('authService', {
    /**
     * Sign in to Plannr
     *
     * @param email
     * @param password
     * @returns {Promise<HTTPResponse | void>}
     */
    async login() {
      await app.store.dispatch('setAppLoading', true);

      return app.$auth
        .loginWith(app.$config.authStrategy)
        .then(() => {
          if (this.loggedIn === false && this.authStrategy === 'plannrProduction') {
            return this.redirectToLaravel();
          }
        })
        .catch(() => {
          return this.redirectToLaravel();
        });
    },

    /**
     * Sign out of Plannr
     *
     * @returns {Promise<void>|Promise<*>}
     */
    async logout() {
      if (this.loggedIn === false) {
        app.redirect('/');
        return;
      }

      const logoutUrl = await app.$auth.user.logout_url;

      if (!logoutUrl) {
        throw Error('Unable to find the logout url from state');
      }

      // Now redirect to the logout page in Plannr to complete the logout.

      window.location.replace(logoutUrl);
    },

    async nuxtLogout() {
      await app.$auth.logout();
    },

    /**
     * Check if the user is logged in
     *
     * @returns {*}
     */
    get loggedIn() {
      return app.$auth.loggedIn;
    },

    get hasState() {
      return app.store.state.user.has_state;
    },

    /**
     * Get the logged-in user
     *
     * @returns {*}
     */
    get user() {
      return app.$auth.user;
    },

    get authStrategy() {
      return app.$config.authStrategy;
    },

    redirectToLaravel() {
      window.location.replace(app.$config.laravelURL);
    },

    /**
     * Refresh the state.
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    refreshState() {
      return app.$axios.get(app.$api.host + '/state', { withCredentials: true }).then(async (rsp) => {
        const appState = rsp.data;

        if (appState.user) {
          await app.$auth.setUser(appState.user);
          await app.store.dispatch('user/setUser', appState.user);
        }

        if (appState.user.current_account.type === 'client') {
          accountAPI.getContact(appState.user.current_account.uuid).then((rsp) => {
            let contact = rsp.data.data;
            app.store.dispatch('client/setActiveClient', contact);
          });
        }

        if (appState.enabledFeatures) {
          await app.store.dispatch('user/setEnabledFeatures', appState.enabledFeatures);
        }

        if (appState.environment) {
          await app.store.dispatch('system/setEnvironment', appState.environment);
        }

        if (appState.sessionFlashData) {
          await app.store.dispatch('session/setSessionFlashData', appState.sessionFlashData);
        }

        if (appState.firmDefaultBrandColours) {
          await app.store.dispatch('system/setFirmDefaultBrandColours', appState.firmDefaultBrandColours);
        }

        if (appState.welcomeWidgetItems) {
          await app.store.dispatch('user/setWelcomeWidget', appState.welcomeWidgetItems);
        }

        if (appState.ipAllowed) {
          await app.store.dispatch('system/setIPAllowed', appState.ipAllowed);
        }

        if (appState.userIpAddress) {
          await app.store.dispatch('system/setUserIPAddress', appState.userIpAddress);
        }

        await app.store.dispatch('setAppLoading', false);

        // Set the broadcast configuration.

        if (this.authStrategy === 'plannrDevelopment') {
          app.$echo.connector.pusher.config.auth.headers['Authorization'] = app.$auth.strategy.token.get();
        }

        await app.store.commit('user/SET_HAS_STATE', true);

        // if (this.authStrategy === 'plannrProduction') {
        app.$echo.connector.pusher.config.auth.withCredentials = true;
        app.$echo.connector.pusher.config.auth.headers['Content-Type'] = 'application/json';
        app.$echo.connector.pusher.config.auth.headers['X-XSRF-TOKEN'] = app.$auth.$storage.getCookies()['XSRF-TOKEN'];

        // }
      }).catch(() => {
        const firm = app.store.state.user.firm
        if (firm.use_branded_auth) {
          window.location.replace(firm.branded_auth_url);
        } else {
          this.redirectToLaravel();
        }
      });
    },
  });
};
