
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'NetworkApp',

  data() {
    return {};
  },

  computed: {
    ...mapState(['appLoading']),
    ...mapState('user', ['user', 'firm']),
    ...mapGetters('system', ['isInProduction']),
    ...mapGetters('user', ['isClient']),
    ...mapGetters('user', {
      allEnabledFeatures: 'allEnabledFeatures',
    }),
  },

  created() {
    if (!this.allEnabledFeatures.includes('ifa_dash_addon')) {
      this.$router.push('/');
    }
  },

  methods: {},
  watch: {},
};
