import { render, staticRenderFns } from "./WelcomeWidget.vue?vue&type=template&id=2042cd21"
import script from "./WelcomeWidget.vue?vue&type=script&lang=js"
export * from "./WelcomeWidget.vue?vue&type=script&lang=js"
import style0 from "./WelcomeWidget.vue?vue&type=style&index=0&id=2042cd21&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetScale: require('/vercel/path0/components/WidgetScale.vue').default,WelcomeWidgetsWidgetItem: require('/vercel/path0/components/welcomeWidgets/widgetItem.vue').default})
