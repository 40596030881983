
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppNotificationEventListener',

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['currentAccountUuid', 'currentLoginUuid']),
  },

  mounted() {
    this.listenForNotifications(this.currentLoginUuid);
  },

  beforeDestroy() {
    this.$echo.leave('login.' + this.currentLoginUuid);
  },

  methods: {
    ...mapActions('notification', ['refreshNotifications']),

    listenForNotifications(uuid) {
      this.$echo
        .private('login.' + uuid)
        .listen('AppNotification')
        .notification((notification) => {
          this.$nuxt.$emit('NewAppNotification', notification);
          // push notification to vuex
          this.refreshNotifications();
          // trigger notification popup
        });
    },
  },
};
