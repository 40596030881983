
import noteAPI from '~/api/notes';
import meAPI from '~/api/me';
import clientAPI from '@/api/clients';
import checkForErrors from '@/helpers/checkForErrors';
export default {
  name: 'create-note',
  components: {},
  props: {
    participants: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      client: {
        uuid: null,
        task_board_uuid: null,
      },
      form: {
        contents: '',
        notable_type: null,
        notable_uuid: null,
        case_uuid: null,
        task_uuid: null,
        plan_uuid: null,
      },
      modelableType: [
        {
          value: 'client',
          label: 'Client',
        },
        {
          value: 'case',
          label: 'Case',
        },
        {
          value: 'task',
          label: 'Task',
        },
        {
          value: 'plan',
          label: 'Plan',
        },
      ],
      linkTo: 'client',
    };
  },
  computed: {
    noteable() {
      if (this.linkTo === 'client') {
        return { type: 'account', uuid: this.client.uuid };
      }
      if (this.linkTo === 'case') {
        return { type: 'case', uuid: this.form.case_uuid };
      }
      if (this.linkTo === 'task') {
        return { type: 'task', uuid: this.form.task_uuid };
      }
      if (this.linkTo === 'plan') {
        return { type: 'plan', uuid: this.form.plan_uuid };
      }
      return { type: null, uuid: null };
    },
  },
  methods: {
    addNote() {
      noteAPI
        .postNotes({
          notable_type: this.noteable.type,
          notable_uuid: this.noteable.uuid,
          contents: this.form.contents,
        })
        .then((rsp) => {
          this.$nuxt.$emit('notification', 'Note added');
          this.form = {
            contents: '',
            notable_type: null,
            notable_uuid: null,
            case_uuid: null,
            task_uuid: null,
            plan_uuid: null,
          };
          this.close();
        })
        .catch((err) => {
          checkForErrors.process(err, this.$refs.formErrorPanel);
        });
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
  watch: {
    'client.uuid': {
      handler(new_val) {
        clientAPI.getClient(new_val).then((rsp) => {
          this.client = rsp.data.data;
        });
      },
    },
  },
};
