import { render, staticRenderFns } from "./AccountSelector.vue?vue&type=template&id=042b2e94"
import script from "./AccountSelector.vue?vue&type=script&lang=js"
export * from "./AccountSelector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Avatar: require('/vercel/path0/components/Avatar.vue').default})
