import paginator from '../helpers/paginator';

export default {
  getAllClients: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/client'),
  getClients: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/client', data),
  createClient: (client) => $nuxt.$axios.post($nuxt.$api.host + '/client', client),
  getClient: (client_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid),
  updateClient: (client_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/client/' + client_uuid, data),
  deleteClient: (client_uuid) => $nuxt.$axios.$delete($nuxt.$api.host + '/client/' + client_uuid),

  getAllLinked: async (client_uuid) =>
    await paginator.grabAllPages($nuxt.$api.host + '/client/' + client_uuid + '/linked-clients'),
  getLinked: (client_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/linked-clients', data),
  getDocuments: (account_uuid, data) =>
    $nuxt.$axios.get($nuxt.$api.host + '/account/' + account_uuid + '/documents', data),
  linkClient: (client_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/client/' + client_uuid + '/linked-clients', data),
  updateLink: (client_uuid, link_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/client/' + client_uuid + '/linked-clients/' + link_uuid, data),
  deleteLink: (client_uuid, link_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/client/' + client_uuid + '/linked-clients/' + link_uuid),

  updateOwnersDetails: (client_uuid, owner_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/client/' + client_uuid + '/owners/personal-details/' + owner_uuid, data),

  manageOwners: (client_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/client/' + client_uuid + '/owners', data),
  getOwners: (client_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/owners'),

  wealthSummary: (client_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/wealth', data),

  getShared: (client_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/shared-accounts'),
};
