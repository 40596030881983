import workflowAPI from '@/api/workflows';
export default {
  namespaced: true,

  state: () => ({
    workflowLoaded: false,
    workflowName: null,
    workflow: [],
    selectedTask: null,
    selectedTaskNumber: null,
    workflowTasksFlattened: null,
    workflowFlattend: [],
  }),

  actions: {
    grabWorkflow({ commit, state, dispatch }, workflow_uuid) {
      commit('SET_WORKFLOW_LOADED', false);

      workflowAPI.getFullTree(workflow_uuid).then((rsp) => {
        dispatch('processWorkflow', rsp.data.data);
      });
    },

    processWorkflow({ commit, state, dispatch }, data) {
      let parent = null;

      commit('SET_WORKFLOW', data.tasks);
      commit('SET_WORKFLOW_LOADED', true);
      commit('SET_WORKFLOW_NAME', data.name);

      dispatch('findSelectedTaskAndParentTask');
      dispatch('flattenWorkflow');
    },

    updateWorkflowName({ commit }, data) {
      commit('SET_WORKFLOW_NAME', data.name);
    },

    clearSelectedTask({ commit }) {
      commit('SET_SELECTED_TASK', null);
    },

    clearLoadedWorkflow({ commit }) {
      commit('SET_WORKFLOW', []);
      commit('SET_WORKFLOW_NAME', null);
    },

    getAllTasksFlattened({ commit }, workflow_uuid) {
      workflowAPI.getTasksFlattened(workflow_uuid).then((rsp) => {
        commit('SET_ALL_WORKFLOW_TASKS', rsp.data.data);
      });
    },

    findSelectedTaskAndParentTask({ commit, state }) {
      let found = null;
      let parent = null;
      if (state.selectedTask === null) {
        commit('SET_SELECTED_TASK', state.workflow[0]);
      } else {
        //find the selected task and update it
        state.workflow.forEach(checkTaskArray.bind(null, parent));
        function checkTaskArray(parent, item, index, array) {
          if (!found) {
            if (item.uuid == state.selectedTask.uuid) {
              found = true;
              commit('SET_SELECTED_TASK', item);
            } else {
              let parent = item;
              item.sub_tasks.forEach(checkTaskArray.bind(null, parent));
              item.trigger_tasks.forEach(checkTaskArray.bind(null, parent));
            }
          }
        }
      }
    },

    flattenWorkflow({ commit, state }) {
      // used for modal selectors
      let tempArray = [];

      state.workflow.forEach(flattenArray);
      function flattenArray(item, index, array) {
        tempArray.push(item);

        item.sub_tasks.forEach(flattenArray);
        item.trigger_tasks.forEach(flattenArray);
      }

      commit('SET_FLATTEN_ARRAY', tempArray);
    },

    setSelectedTaskNumber({ commit }, task_number) {
      commit('SET_SELECTED_TASK_NUMBER', task_number);
    },
    setSelectedTask({ commit, dispatch }, task) {
      commit('SET_SELECTED_TASK', task);
    },
  },
  mutations: {
    SET_WORKFLOW_LOADED(state, data) {
      state.workflowLoaded = data;
    },
    SET_WORKFLOW_NAME(state, workflow_name) {
      state.workflowName = workflow_name;
    },
    SET_WORKFLOW(state, workflow) {
      state.workflow = workflow;
    },
    SET_ALL_WORKFLOW_TASKS(state, workflow) {
      state.workflowTasksFlattened = workflow;
    },
    SET_FLATTEN_ARRAY(state, tasks) {
      state.workflowFlattend = tasks;
    },
    SET_SELECTED_TASK_NUMBER(state, task_number) {
      state.selectedTaskNumber = task_number;
    },

    SET_SELECTED_TASK(state, task) {
      state.selectedTask = task;
    },
  },
};
