
import { mapActions, mapGetters, mapState } from 'vuex';
import notificationAPI from '@/api/notifications';

export default {
  name: 'notificationIcon',

  data() {
    return {
      newNotifications: [],
      lastUuid: null,
    };
  },

  computed: {
    ...mapState('notification', ['notifications', 'unread']),
    ...mapGetters('user', ['isClient']),
  },

  mounted() {
    this.$nuxt.$on('NewAppNotification', this.triggerNotification);
  },

  beforeDestroy() {
    this.$nuxt.$off('NewAppNotification');
  },

  methods: {
    ...mapActions('notification', ['refreshNotifications']),

    openNotificationPopup() {
      this.$nuxt.$emit('OpenNotificationModal');
    },

    triggerNotification(notification) {
      if (notification.id !== this.lastUuid) {
        this.reactToNewNotification(notification);
        this.lastUuid = notification.id;
      }
    },

    reactToNewNotification(notification) {
      // get the notification via API
      if (notification) {
        notificationAPI.getNotification(notification.id).then((rsp) => {
          this.$nuxt.$emit(
            'notification',
            `${rsp.data.data.description}`,
            'notification',
            true,
            5000,
            rsp.data,
            `${rsp.data.data.title}`
          );
        });
      }
    },
  },
};
