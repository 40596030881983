
import { Crisp } from 'crisp-sdk-web';
import { mapState } from 'vuex';

export default {
  name: 'CrispChat',
  computed: {
    ...mapState('user', ['user', 'currentAccount']),
  },
  mounted() {
    Crisp.configure('3c92c050-f137-4c5c-991f-f3fa2d562f2a');
    Crisp.setTokenId(this.user.uuid);
    Crisp.user.setEmail(this.user.email);
    Crisp.user.setNickname(this.user.name);
    Crisp.user.setCompany(this.currentAccount.firm.name, {});
  },
};
