
import clientAPI from '../api/clients';
import { debounce } from 'lodash';

export default {
  name: 'ClientSelector',

  props: {
    value: {
      type: String | null,
      required: true,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Select client',
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    linkedTo: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selected: {},
      clientList: [],
      loading: false,
    };
  },

  async created() {
    if (this.value !== null) {
      const response = await clientAPI.getClient(this.value);

      this.selected = response.data.data;
    }
  },

  methods: {
    grabClients: debounce(async function (val) {
      if (val.length >= 3) {
        this.loading = true;

        let params = {
          'filter[name]': val,
          per_page: 200,
        };

        if (this.linkedTo) {
          params['filter[linked_to]'] = this.linkedTo;
        }

        const rsp = await clientAPI.getClients({ params: params });

        this.clientList = [...rsp.data.data];

        this.loading = false;
      } else if (!val.length) {
        this.clientList = [];
      }
    }, 500),

    resetSelectedList() {
      this.selectedList = [];
    },
  },

  watch: {
    value: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          const response = await clientAPI.getClient(newVal);
          this.selected = response.data.data;
        }
      },
    },

    selected: {
      deep: true,
      handler(new_val) {
        if (new_val !== null) {
          if (new_val instanceof Array) {
            this.$emit('input', this.returnObject ? new_val[0] : new_val[0].uuid);
          } else {
            this.$emit('input', this.returnObject ? new_val : new_val.uuid);
          }
        } else {
          this.$emit('input', null);
        }
      },
    },
  },
};
