
import accountSwitcher from '@/api/accountSwitcher';
import { mapState } from 'vuex';

export default {
  name: 'NavigationAccountSwitcher',

  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('user', ['currentAccount']),
  },

  data: () => {
    return {
      accounts: [],
    };
  },

  created() {
    this.grabAccounts();
  },

  methods: {
    grabAccounts() {
      accountSwitcher.getAccounts().then((rsp) => {
        this.accounts = rsp.data.data;
      });
    },

    switchTo(login) {
      accountSwitcher.switchTo({ account_uuid: login.account.uuid }).then((rsp) => {
        location.reload();
      });
    },

    getRole(account) {
      if (account.type === 'client') {
        return 'Client';
      }

      return this.$helpers.sentencify(account.role ?? '');
    },
  },
};
