import formsAPI from '~/api/formBuilder';

export default {
  namespaced: true,

  state: () => ({
    form: {
      title: null,
      description: null,
      sections: [],
      live: '',
      visibility: '',
      questions: {},
    },
    formLoaded: false,
    draggableFields: 'linked',
    customFields: [],
    linkedFields: [],
    regularFields: [
      {
        name: 'Boolean',
        description: 'Adds a boolean field',
        type: 'boolean',
        field: 'boolean',
      },
      {
        name: 'Checkbox',
        description: 'Adds a checkbox select field',
        type: 'checkbox',
        field: 'checkbox',
      },
      {
        name: 'Date',
        description: 'Adds a date field',
        type: 'date',
        field: 'date',
      },
      {
        name: 'Email',
        description: 'Adds an email field',
        type: 'email',
        field: 'email',
      },
      {
        name: 'Number',
        description: 'Adds a number field',
        type: 'number',
        field: 'number',
      },
      {
        name: 'Phone',
        description: 'Adds a telephone field',
        type: 'phone',
        field: 'phone',
      },
      {
        name: 'Radio',
        description: 'Adds a radio select field',
        type: 'radio',
        field: 'radio',
      },
      {
        name: 'Rating',
        description: 'Adds a rating field',
        type: 'rating',
        field: 'rating',
      },
      {
        name: 'Selection',
        description: 'Adds a selection field',
        type: 'selection',
        field: 'selection',
      },
      {
        name: 'Text',
        description: 'Adds a text field',
        type: 'string',
        field: 'string',
      },
      {
        name: 'Textarea',
        description: 'Adds a textarea field',
        type: 'longtext',
        field: 'longtext',
      },
    ],
  }),

  actions: {
    clearFormInState({ commit }) {
      let form = {
        title: null,
        description: null,
        sections: [],
        live: '',
        visibility: '',
        questions: {},
      };
      commit('SET_FORM_LOADED', false);
      commit('SET_FORM', form);
    },
    getSingleFormFromState({ commit, dispatch }, form_uuid) {
      commit('SET_FORM_LOADED', false);
      formsAPI.getSingleForm(form_uuid).then((rsp) => {
        commit('SET_FORM_LOADED', true);
        commit('SET_FORM', rsp.data.data);
        dispatch('resetAllLinkedFields');
        dispatch('checkForLinkedFieldsInForm');
        dispatch('resetAllCustomFields');
        dispatch('checkForCustomFieldsInForm');
      });
    },
    editFormMeta({ commit }, data) {
      if (data.title) {
        commit('SET_TITLE', data.title);
      }
      if (data.description) {
        commit('SET_DESCRIPTION', data.description);
      }
      if (data.visibility) {
        commit('SET_VISIBILITY', data.visibility);
      }
      if (data.live !== null || data.live !== 'undefined') {
        commit('SET_LIVE', data.live);
      }
      if (data.allowed_hosts) {
        commit('SET_ALLOWED_HOSTS', data.allowed_hosts);
      }
      if (data.client_type) {
        commit('SET_CLIENT_TYPE', data.client_type);
      }
      if (data.create_client) {
        commit('SET_CREATE_CLIENT', data.create_client);
      }
    },
    getLinkedFields({ commit, dispatch }, data) {
      formsAPI.getAllLinkedQuestions().then((rsp) => {
        const sortedArray = this.$helpers.sortBy('label', rsp.data.data);
        commit('SET_LINKED_FIELDS', sortedArray);
        dispatch('resetAllLinkedFields');
        dispatch('checkForLinkedFieldsInForm');
      });
    },
    resetAllLinkedFields({ commit, state }, data) {
      if (state.formLoaded) {
        state.linkedFields.forEach((block, index) => {
          // RESETS ALL FIELDS
          let field = {};
          field.index = index;
          field.value = false;
          commit('SET_LINKEDFIELDS_BLOCK_DISABLED', field);
        });
      }
    },
    checkForLinkedFieldsInForm({ commit, state }, data) {
      if (state.formLoaded) {
        state.form.sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.linked_question) {
              let disabledIndex = state.linkedFields.findIndex((el) => {
                return el.field === question.linked_question.data.field;
              });
              let field = {};
              field.index = disabledIndex;
              field.value = true;

              if (disabledIndex !== -1) {
                commit('SET_LINKEDFIELDS_BLOCK_DISABLED', field);
              }
            }
          });
        });
      }
    },

    getCustomFields({ commit, dispatch }, data) {
      formsAPI.getAllCustomFieldQuestions().then((rsp) => {
        const sortedArray = this.$helpers.sortBy('label', rsp.data.data);
        commit('SET_CUSTOM_FIELDS', sortedArray);
        dispatch('resetAllCustomFields');
        dispatch('checkForCustomFieldsInForm');
      });
    },

    resetAllCustomFields({ commit, state }, data) {
      if (state.formLoaded) {
        state.customFields.forEach((block, index) => {
          // RESETS ALL FIELDS
          let field = {};
          field.index = index;
          field.value = false;
          commit('SET_CUSTOMFIELDS_BLOCK_DISABLED', field);
        });
      }
    },
    checkForCustomFieldsInForm({ commit, state }, data) {
      if (state.formLoaded) {
        state.form.sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.linked_question) {
              let disabledIndex = state.customFields.findIndex((el) => {
                return el.field === question.linked_question.data.field;
              });
              let field = {};
              field.index = disabledIndex;
              field.value = true;

              if (disabledIndex !== -1) {
                commit('SET_CUSTOMFIELDS_BLOCK_DISABLED', field);
              }
            }
          });
        });
      }
    },

    setDraggableFieldType({ commit }, field_type) {
      commit('SET_DRAGGABLE_FIELDS', field_type);
    },
    updateQuestionFromResponse({ commit }, data) {
      commit('UPDATE_QUESTION', data);
    },
  },

  mutations: {
    SET_FORM_LOADED(state, data) {
      state.formLoaded = data;
    },
    SET_FORM(state, form) {
      state.form = form;
    },
    SET_DRAGGABLE_FIELDS(state, field_type) {
      state.draggableFields = field_type;
    },
    SET_LINKED_FIELDS(state, data) {
      let fields = [];

      data.forEach((element) => {
        let field = {};

        let name = element.label;
        field.name = name;
        field.type = element.type;
        field.field = element.field;
        field.disabled = false;

        if (element.force_required) {
          field.force_required = element.force_required;
          field.required = true;
        } else {
          field.required = false;
        }

        field.required = false;
        field.selection_options = element.selection_options;

        fields.push(field);
      });

      state.linkedFields = fields;
    },
    SET_CUSTOM_FIELDS(state, data) {
      let fields = [];

      data.forEach((element) => {
        let field = {};

        let name = element.field;

        name = name.replace(/_/g, ' ');
        field.name = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
        field.type = element.type;
        field.field = element.field;
        field.disabled = false;

        if (element.force_required) {
          field.required = element.force_required;
        } else {
          field.required = false;
        }
        field.selection_options = element.selection_options;

        fields.push(field);
      });

      state.customFields = fields;
    },
    SET_TITLE(state, title) {
      state.form.title = title;
    },
    SET_DESCRIPTION(state, description) {
      state.form.description = description;
    },
    SET_VISIBILITY(state, visibility) {
      state.form.visibility = visibility;
    },
    SET_LIVE(state, live) {
      state.form.live = live;
    },
    SET_ALLOWED_HOSTS(state, allowed_hosts) {
      state.form.allowed_hosts = allowed_hosts;
    },
    SET_CLIENT_TYPE(state, client_type) {
      state.form.client_type = client_type;
    },
    SET_CLIENT_TYPE(state, client_type) {
      state.form.client_type = client_type;
    },
    SET_CREATE_CLIENT(state, create_client) {
      state.form.create_client = create_client;
    },
    SET_LINKEDFIELDS_BLOCK_DISABLED(state, data) {
      if (state.linkedFields.length > 0) {
        state.linkedFields[data.index].disabled = data.value;
      }
    },
    SET_CUSTOMFIELDS_BLOCK_DISABLED(state, data) {
      if (state.customFields.length > 0) {
        state.customFields[data.index].disabled = data.value;
      }
    },
    UPDATE_QUESTION(state, data) {
      let sectionIndex = 0;
      let questionIndex = 0;
      state.form.sections.forEach((section, sectionIndex) => {
        questionIndex = section.questions.findIndex((question) => {
          return question.uuid === data.uuid;
        });
      });

      state.form.sections[sectionIndex].questions[questionIndex] = data;
    },
  },
};
