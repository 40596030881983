
export default {
  name: 'ParticipantsAvatars',

  props: {
    participants: {
      type: Array,
      required: true,
    },
    avatarCount: {
      type: Number,
      required: false,
      default: 2,
    },
    size: {
      type: Number,
      required: false,
      default: 30,
    },
  },

  computed: {
    avatars() {
      if (this.participants.length > this.avatarCount) {
        let reduced = this.participants.slice(0, this.avatarCount);
        return reduced.map((item) => {
          return { name: item.name, uuid: item.uuid, type: item.type };
        });
      }
      return this.participants.map((item) => {
        return { name: item.name, uuid: item.uuid, type: item.type };
      });
    },

    remaining() {
      return this.participants.length - this.avatars.length;
    },
    remainingNames() {
      let reduced = this.participants.slice(this.avatarCount);
      let html = reduced.map((item) => {
        return '<li><nuxt-link :to="routeTo(item)">' + item.name + '</nuxt-link></li>';
      });
      return '<ul>' + html.join(' ') + '</ul>';
    },
  },

  methods: {
    routeTo(user) {
      return '/contacts/' + user.uuid;
    },
  },
};
