
import searchAPI from '../api/search';

export default {
  name: 'SearchBlock',

  data() {
    return {
      opened: false,
      form: {
        q: null,
      },
      results: [],
      activeFilters: [],
      filterOptions: [],
    };
  },

  computed: {
    filteredResultsList() {
      return this.results.filter((item) => this.activeFilters.includes(item.model.type));
    },
  },

  mounted() {
    this.$nuxt.$on('search', this.searchNow);
    this.$nuxt.$on('searchClose', this.closePanel);
  },

  methods: {
    async searchNow(query) {
      this.form.q = query;
      const rsp = await searchAPI.search({ params: this.form });
      this.results = rsp.data.data;
      this.opened = true;
      this.buildFilterGroups();
    },

    closePanel() {
      this.opened = false;
    },

    buildFilterGroups() {
      let options = this.results.map((item) => item.model.type);
      options = [...new Set(options)];
      this.filterOptions = options;
      this.activeFilters = options;
    },
  },
};
