import paginator from '@/helpers/paginator';

export default {
  postNotes: (data = null) => $nuxt.$axios.post($nuxt.$api.host + '/note', data),
  deleteNote: (data = null) => $nuxt.$axios.delete($nuxt.$api.host + '/note/' + data),
  getNotes: (notable_type, notable_uuid, params = null) =>
    $nuxt.$axios.get(`${$nuxt.$api.host}/note/${notable_type}/${notable_uuid}`, params),
  getAllNotesForClient: (client_uuid, data = null) =>
    $nuxt.$axios.get(`${$nuxt.$api.host}/client/${client_uuid}/all-notes`, data),
  getNotesPaginated: async (notable_type, notable_uuid, params = null) =>
    await paginator.grabAllPages(`${$nuxt.$api.host}/note/${notable_type}/${notable_uuid}`, {}, params),
  updateNote: (notable_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/note/' + notable_uuid, data),
};
