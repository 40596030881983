
import { mapState, mapActions } from 'vuex';

export default {
  name: 'notifications-bar',
  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'alert',
    },
    uuid: {
      type: String,
      required: true,
    },
    timeout: {
      type: Number,
      required: false,
      default: 5000,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState('multiSelect', ['bulkActionProgressInfo']),

    animation() {
      return `animation: ${this.timeout}ms linear theme-color, ${this.timeout}ms ease-out enter forwards`;
    },
  },

  methods: {
    ...mapActions('notification', ['redirectTo', 'markAsRead']),

    redirectItem(notification, type) {
      if (type === 'notification') {
        this.$emit('clicked');
        this.markAsRead(notification);
        this.redirectTo(notification);
      }
    },
  },
};
