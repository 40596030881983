
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SessionExpiry',
  data() {
    return {
      timer: null,
      minutes: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },
  created() {
    this.isOpen = false;
    this.checkSession();
    this.timer = setInterval(() => {
      this.checkSession();
    }, 60000); // runs every minute
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    ...mapActions('user', ['logExtendSession']),
    checkSession() {
      let now = this.$dayjs();
      let expires = this.$dayjs(this.user.session_expires_at);
      this.minutes = expires.diff(now, 'minutes');
      if (this.minutes <= 5) {
        this.isOpen = true;
      }
    },

    extendSession() {
      this.$axios.get(this.$nuxt.$api.laravelURL + '/refresh').then((rsp) => {
        this.$nuxt.$emit('notification', `We've just extended your session again`);
        this.logExtendSession();
        this.$nuxt.$authService.refreshState();
        this.isOpen = false;
      });
    },
  },
};
