
import {mapState, mapActions} from "vuex";

export default {
  head: {
    bodyAttrs: {
      class: 'min-h-screen'
    }
  },

  created() {
    this.setAppLoading(false);
  },

  methods: {
    ...mapActions(['setAppLoading'])
  },

  computed: {
    ...mapState(['appLoading'])
  }
}
