export default {
  getAllModels: (data) =>
    $nuxt.$axios.get(`${$nuxt.$api.host}/custom-default-folder`, data),
  createModel: (data) =>
    $nuxt.$axios.post(`${$nuxt.$api.host}/custom-default-folder`, data),
  getModel: (structureUuid) =>
    $nuxt.$axios.get(`${$nuxt.$api.host}/custom-default-folder/${structureUuid}`),
  updateModel: (data, modelUuid) =>
    $nuxt.$axios.patch(`${$nuxt.$api.host}/custom-default-folder/${modelUuid}`, data),
  deleteModel: (structureUuid) =>
    $nuxt.$axios.delete(`${$nuxt.$api.host}/custom-default-folder/${structureUuid}`),
};
