
export default {
  name: 'result-icon',

  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
