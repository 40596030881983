const AccountTypes = Object.freeze({
  Client: 'client',
  Employee: 'employee',
  External: 'external',
});

const ClientRoles = Object.freeze({
  Individual: 'individual',
  Joint: 'joint',
  Trust: 'trust',
  Business: 'business',
});

const ClientMultiPartyRoles = Object.freeze({
  Joint: ClientRoles.Joint,
  Trust: ClientRoles.Trust,
  Business: ClientRoles.Business,
});

const EmployeeRoles = Object.freeze({
  Owner: 'owner',
  Admin: 'admin',
  Member: 'member',
});

const ExternalRoles = Object.freeze({
  Introducer: 'introducer',
});

module.exports = {
  AccountTypes,
  ClientRoles,
  ClientMultiPartyRoles,
  EmployeeRoles,
  ExternalRoles,
};
