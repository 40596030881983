export default {
  data() {
    return {
      tour: null,
    };
  },

  created() {
    this.defineTour();
  },

  mounted() {
    this.$nuxt.$on('triggerTour', this.openTour);
  },

  methods: {
    defineTour() {},

    openTour() {
      if (this.tour !== null) {
        if (this.tour.isActive) {
          this.tour.cancel();
        }
        this.tour.start();
      } else {
        this.$nuxt.$emit(
          'notification',
          "Sadly, there's no tour for this page. Why not request one one our roadmap?",
          'warning'
        );
      }
    },
  },

  beforeDestroy() {
    this.$nuxt.$off('triggerTour');
  },
};
