
export default {
  name: 'HoursMinutesInput',

  props: {
    value: {
      type: Number | null,
      required: true,
    },
  },

  computed: {
    hours: {
      get: function () {
        if (this.value) {
          let totalMinutes = parseInt(this.value);
          return Math.floor(totalMinutes / 60);
        }
      },
      set: function (newVal, oldVal) {
        if (newVal !== null && newVal !== oldVal) {
          let totalHours = newVal * 60;
          let total = 0;
          if (this.minutes) {
            total = parseInt(totalHours) + parseInt(this.minutes);
          } else {
            total = parseInt(totalHours);
          }
          this.$emit('input', total);
        }
      },
    },

    minutes: {
      get: function () {
        if (this.value) {
          let totalMinutes = this.value;
          return totalMinutes % 60;
        }
      },
      set: function (newVal) {
        if (newVal !== null) {
          let totalHours = 0;
          if (this.hours) {
            totalHours = this.hours * 60;
          }
          let total = parseInt(totalHours) + parseInt(newVal);
          this.$emit('input', total);
        }
      },
    },
  },
};
