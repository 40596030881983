export default {
  process: (err, ref) => {
    if (err.response?.status === 422) {
      // validate error, do something
      if( ref ) {
        ref.setErrors(err.response.data.errors);
      }
      return false;
    } else {
      return Promise.reject(err)
    }
  },

  reset: (ref) => {
    ref.setErrors([])
  }
}
