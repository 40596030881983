
import { mapGetters, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';

export default {
  name: 'NavigationNavContractedSection',

  props: {
    section: {
      type: Object,
      required: true,
    },
    click: {
      type: Boolean,
      default: true,
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      isExpanded: false,
      flyout: null,
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isMember']),

    classes() {
      let urls = [];
      if (this.section.children !== undefined) {
        urls = this.section.children.map((item) => item.to);
      }
      urls.push(this.section.to);

      if (urls.includes(this.$route.path)) {
        return 'border-r-4 border-text-branding-sidebar-active text-branding-sidebar-active border-branding-sidebar-active';
      } else {
        return 'text-branding-sidebar-text';
      }
    },

    iconClasses() {
      let urls = [];
      if (this.section.children !== undefined) {
        urls = this.section.children.map((item) => item.to);
      }
      urls.push(this.section.to);
      if (urls.includes(this.$route.path)) {
        return 'text-branding-sidebar-active';
      } else {
        return 'text-branding-sidebar-text';
      }
    },
  },
  methods: {
    sectionClicked() {
      this.isExpanded = !this.isExpanded;
      this.checkMenuPosition();
    },
    navigateTo(link) {
      this.isExpanded = false;
      this.$router.push({ path: link });
    },
    menuMouseOver() {
      this.isExpanded = true;
      this.checkMenuPosition();
    },
    menuMouseOff() {
      this.isExpanded = false;
    },
    checkMenuPosition() {
      if (this.isExpanded) {
        this.$nextTick(() => {
          let flyout = this.$refs.flyout;
          let arrow = this.$refs.arrow;
          let bounds = flyout.getBoundingClientRect();

          if (bounds.bottom > window.innerHeight) {
            let menuOffest = bounds.bottom - window.innerHeight + 8;
            let arrowOffset = menuOffest + 20;
            this.$gsap.set(flyout, { top: -menuOffest });
            this.$gsap.set(arrow, { top: arrowOffset });
          }
        });
      }
    },
  },
};
