
import conversationAPI from '@/api/conversations';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'conversation-modal',

  data() {
    return {
      viewing: {},
      mode: 'list',
      spotlight: 'firm',
      spotlight_uuid: null,
      newParticipants: [],
    };
  },

  computed: {
    ...mapState('notification', ['openConversationUuid']),

    openModalFromNotification() {
      if (this.openConversationUuid !== null) {
        return true;
      }
    },
  },

  mounted() {
    this.$nuxt.$on('viewConvo', this.changeConvo);
    this.$nuxt.$on('openConvo', this.openConvo);
    this.$nuxt.$on('viewInbox', this.viewInbox);
    this.$nuxt.$on('viewClientInbox', this.viewClientInbox);
    this.$nuxt.$on('newConvo', this.newConvo);
    this.$nuxt.$on('newConvoForClient', this.newConvoForClient);
    this.$nuxt.$on('newConvoForCase', this.newConvoForCase);
    this.$nuxt.$on('refreshThread', this.refreshConversation);
  },

  beforeDestroy() {
    this.$nuxt.$off('viewConvo');
    this.$nuxt.$off('viewClientInbox');
    this.$nuxt.$off('openConvo');
    this.$nuxt.$off('viewInbox');
    this.$nuxt.$off('newConvo');
    this.$nuxt.$off('newConvoForClient');
    this.$nuxt.$off('newConvoForCase');
    this.$nuxt.$off('refreshThread');
  },

  methods: {
    ...mapActions('notification', ['unsetConversationUuid']),

    open() {
      this.mode = 'list';
      this.checkForSpotlightNavigation();
      this.$refs.convo.open();
    },

    close() {
      this.$refs.convo.close();
      this.unsetConversationUuid();
    },

    async openConvo(conversation) {
      if (typeof conversation === 'string') {
        this.viewing.uuid = conversation;
        await this.refreshConversation();
      } else {
        this.checkForSpotlightNavigation();
        this.changeConvo(conversation);
      }
      this.$refs.convo.open();
    },

    changeConvo(conversation) {
      this.viewing = conversation;
      this.mode = 'single';
    },

    checkForSpotlightNavigation() {
      let route = this.$route.path;
      if (route.startsWith('/contacts/')) {
        this.spotlight = 'client';
        this.spotlight_uuid = this.$route.params.id;
      }

      if (route.startsWith('/cases/')) {
        this.spotlight = 'case';
        this.spotlight_uuid = this.$route.params.id;
      }
    },

    viewInbox() {
      this.$refs.convo.open();
      this.viewing = {};
      this.mode = 'list';
    },

    viewClientInbox() {
      this.open();
      this.viewing = {};
      this.mode = 'list';
    },

    newConvo() {
      this.viewing = {};
      this.mode = 'new';
    },

    newConvoForClient(participants) {
      this.open();
      this.newParticipants = participants;
      this.viewing = {};
      this.mode = 'new';
    },

    newConvoForCase(participants) {
      this.open();
      this.newParticipants = participants;
      this.viewing = {};
      this.mode = 'new';
    },

    refreshConversation() {
      conversationAPI.getConversation(this.viewing.uuid).then((rsp) => {
        this.changeConvo(rsp.data.data);
      });
    },
  },

  watch: {
    openModalFromNotification: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.openConvo(this.openConversationUuid);
        }
      },
    },
  },
};
