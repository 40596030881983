
import Vue from 'vue';
import { Toolbar, Link, Image, HtmlEditor, PasteCleanup } from '@syncfusion/ej2-vue-richtexteditor';
export default Vue.extend({
  name: 'RichTextEditor',
  props: {
    value: {
      type: String,
      default: '<p></p>',
    },

    colour: {
      type: String,
      default: 'dark',
    },
    variant: {
      type: String,
      default: 'full',
    },
  },
  data: function () {
    return {
      firstFocus: true,
      v: '<p></p>',
      simpleSettings: {
        items: ['Bold', 'Italic', 'Underline', '|', 'Formats', 'OrderedList', 'UnorderedList'],
      },
      pasteCleanUpSettings: {
        keepFormat: false,
        deniedAttrs: ['class', 'title', 'id'],
        deniedTags: ['div', 'script', 'span'],
        allowedStyleProps: []
      },
      fullSettings: {
        items: [
          'Bold',
          'Italic',
          'Underline',
          '|',
          'Formats',
          'Alignments',
          'OrderedList',
          'UnorderedList',
          'BackgroundColor',
          '|',
          'CreateLink',
          'Image',
          '|',
          'SourceCode',
          '|',
          'Undo',
          'Redo',
        ],
      },
    };
  },
  beforeDestroy() {
    const tooltip = this.$refs?.editor?.ej2Instance?.toolbarModule?.toolbarRenderer?.tooltip;

    if (typeof tooltip?.destroy === 'function') {
      tooltip.destroy();
    }
  },
  computed: {
    editorColour() {
      if (this.colour === 'dark') {
        return 'rteditor';
      }
      return '';
    },

    editorVariant() {
      if (this.variant === 'full') {
        return this.fullSettings;
      }
      return this.simpleSettings;
    },
  },
  methods: {
    focusTextEditor(e) {
      const editor = this.$refs.editor;

      if (editor && this.firstFocus) {
        editor.focusIn();
        this.firstFocus = false;
      }
    },
  },
  provide: {
    richtexteditor: [Toolbar, Link, Image, HtmlEditor, PasteCleanup],
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.v = newVal;
      },
    },
    v(newVal) {
      this.$emit('input', newVal);
    },
  },
});
