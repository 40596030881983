import paginator from '../helpers/paginator';

export default {
  createContact: (account) => $nuxt.$axios.post($nuxt.$api.host + '/account', account),
  getContact: (account_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/account/' + account_uuid),
  updateContact: (account_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/account/' + account_uuid, data),
  deleteContact: (account_uuid) => $nuxt.$axios.$delete($nuxt.$api.host + '/account/' + account_uuid),

  getAllAccounts: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/account'),
  getAccounts: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/account', data),
  getAccountContactDetails: (account_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/account/' + account_uuid + '/contact-detail'),
  deleteContactDetail: (account_uuid, contact_detail_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/account/' + account_uuid + '/contact-detail/' + contact_detail_uuid),
  createContactDetail: (account_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/account/' + account_uuid + '/contact-detail', data),
  editContactDetail: (account_uuid, contact_detail_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/account/' + account_uuid + '/contact-detail/' + contact_detail_uuid, data),
};
