
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Navigation',

  data() {
    return {
      items: [
        {
          to: '/dashboard',
          icon: 'fa-home',
          text: 'Dashboard',
          children: [
            {
              to: '/dashboard',
              text: 'My overview',
              restricted: [],
            },
            {
              to: '/dashboard/my-logged-time',
              text: 'My logged time',
              restricted: [],
            },
            {
              to: '/dashboard/my-tasks',
              text: 'My tasks',
              restricted: [],
            },
          ],
          restricted: [],
        },
        {
          to: '/contacts',
          icon: 'clients',
          text: 'Contacts',
          children: [
            {
              to: '/contacts',
              text: 'Contacts',
              restricted: [],
            },
            {
              to: '/plans',
              text: 'Plans',
              restricted: [],
            },
          ],
          restricted: [],
        },
        {
          to: '/cases',
          icon: 'cases',
          text: 'Cases',
          restricted: [],
        },
        {
          to: '/business/key-metrics',
          icon: 'excel',
          text: 'Business',
          children: [
            {
              to: '/business/key-metrics',
              text: 'Key Metrics',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/automations',
              text: 'Automations',
              restricted: [],
            },
            {
              to: '/business/docs',
              text: 'Document Builder',
              restricted: [],
            },
            {
              to: '/business/forms',
              text: 'Form Builder',
              restricted: [],
            },
            {
              to: '/business/signatures',
              text: 'Signature Requests',
              restricted: [],
            },
            {
              to: '/business/income-reconciliation',
              text: 'Income Reconciliation',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/business/reporting',
              text: 'Reporting / Exports',
              restricted: ['owners', 'admins'],
            },
            {
              to: '/business/imports',
              text: 'Imports',
              restricted: ['owners'],
            },
          ],
          restricted: [],
        },
        {
          to: '/settings',
          icon: 'gear',
          text: 'Settings',
          restricted: ['owners', 'admins'],
        },
      ],
    };
  },

  computed: {
    ...mapState('user', ['user', 'enabledFeatures']),
    ...mapState('user', ['currentAccount', 'currentLogin']),
    ...mapGetters('user', [
      'isPortalUser',
      'isClient',
      'isEmployee',
      'isExternal',
      'isOwner',
      'isAdmin',
      'isMember',
      'isNotSeniorEmployee',
    ]),

    displayMyWealth() {
      if (this.currentLogin.welcome_enabled === true) {
        return 'My Wealth';
      } else {
        return 'Portfolio';
      }
    },

    clientItems() {
      return [
        {
          to: '/portal',
          icon: 'fa-home',
          text: 'Dashboard',
        },
        {
          to: '/portal/my-details',
          icon: 'fa-user',
          text: 'About Me',
        },
        {
          to: '/portal/requests',
          icon: 'fa-file-circle-question',
          text: 'Requests',
          show: this.isPortalUser && this.currentLogin.factfind_enabled,
        },
        {
          to: '/portal/conversations',
          icon: 'fa-comments',
          text: 'Conversations',
        },
        {
          to: '/portal/documents',
          icon: 'fa-folders',
          text: 'Documents',
        },
        {
          to: '/portal/my-forms',
          icon: 'fa-edit',
          text: 'My Forms',
        },
        {
          to: '/portal/my-tasks',
          icon: 'fa-square-check',
          text: 'My Tasks',
          show: this.isPortalUser && this.currentLogin.tasks_enabled,
        },
        {
          to: '/portal/my-wealth',
          icon: 'fa-bar-chart',
          text: this.displayMyWealth,
          show: this.isPortalUser && this.currentLogin.wealth_enabled,
        },
        {
          to: '/portal/my-illustrations',
          icon: 'fa-chart-line-up',
          text: 'My Illustrations',
          show: this.isPortalUser && this.enabledFeatures.includes('illustrations'),
        },
        {
          to: '/portal/goals',
          icon: 'fa-trophy',
          text: 'Financial Goals',
          show: this.isPortalUser && this.currentLogin.goals_enabled,
        },
      ];
    },

    employeeItems() {
      return [
        {
          to: '/portal',
          icon: 'fa-home',
          text: 'Dashboard',
        },
        {
          to: '/portal/my-details',
          icon: 'fa-user',
          text: 'My Info',
        },
        {
          to: '/portal/conversations',
          icon: 'fa-comments',
          text: 'Conversations',
        },
        {
          to: '/portal/documents',
          icon: 'fa-files',
          text: 'Documents',
        },
        {
          to: '/portal/cases',
          icon: 'fa-folders',
          text: 'Cases',
        },
        {
          to: '/portal/my-tasks',
          icon: 'fa-square-check',
          text: 'My Tasks',
        },
      ];
    },

    externalItems() {
      return [
        {
          to: '/portal',
          icon: 'fa-home',
          text: 'Dashboard',
        },
        {
          to: '/portal/my-details',
          icon: 'fa-user',
          text: 'My Info',
        },
        {
          to: '/portal/contacts',
          icon: 'fa-users',
          text: 'Contacts',
        },
        {
          to: '/portal/conversations',
          icon: 'fa-comments',
          text: 'Conversations',
        },
        {
          to: '/portal/documents',
          icon: 'fa-folders',
          text: 'Documents',
        },
      ];
    },

    navigation() {
      if (this.isClient) {
        return this.clientItems.filter((parent) => this.filterLinks(parent));
      }
      if (this.isEmployee) {
        return this.employeeItems.filter((parent) => this.filterLinks(parent));
      }
      if (this.isExternal) {
        return this.externalItems.filter((parent) => this.filterLinks(parent));
      }
    },
    navItems() {
      return this.items.filter((parent) => this.filterLinks(parent));
    },
  },

  methods: {
    filterLinks(item) {
      if (item.hasOwnProperty('show')) {
        return item.show;
      }

      if (!item.hasOwnProperty('restricted') || !Array.isArray(item.restricted)) {
        return true;
      }

      if (item.restricted.some((r) => ['owners', 'admins'].includes(r)) && this.isNotSeniorEmployee) {
        return false;
      }

      if (item.hasOwnProperty('children') && Array.isArray(item.children)) {
        item.children = item.children.filter((child) => this.filterLinks(child));
      }

      return true;
    },
  },
};
