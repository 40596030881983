
import accountSwitcher from '@/api/accountSwitcher';
import { mapState, mapActions } from 'vuex';

export default {
  title: 'NavigationSidebarMobile',
  data() {
    return {
      menuOpen: false,
      showAccountBlock: false,
      showOverlay: false,
      accounts: [],
    };
  },
  computed: {
    ...mapState('user', ['currentAccount']),
    ...mapState('navigation', ['navigationItems']),
  },
  mounted() {
    this.$nuxt.$on('openMobileMenu', this.openMobileMenu);
    let menu = this.$refs.mobileMenu;
    let wrapper = this.$refs.mobileMenuWrapper;
    let overlay = this.$refs.mobileMenuOverlay;
    let gsap = this.$gsap;

    gsap.set(menu, { x: -400 });
    gsap.set(wrapper, { autoAlpha: 0 });
    gsap.set(overlay, { autoAlpha: 0 });

    this.tl = gsap.timeline({
      onComplete: () => {},
    });

    this.tl
      .set(wrapper, { autoAlpha: 1 })

      .to(overlay, { autoAlpha: 1, duration: 0.25 })
      .to(menu, { x: 0, duration: 0.5 });

    this.tl.pause();
  },
  beforeDestroy() {
    this.$nuxt.$off('openMobileMenu');
  },
  methods: {
    grabAccounts() {
      accountSwitcher.getAccounts().then((rsp) => {
        this.accounts = rsp.data.data;
      });
    },
    openMobileMenu() {
      this.menuOpen = true;
      this.tl.play();
    },
    closeMenu() {
      this.menuOpen = false;
      this.tl.reverse();
    },
  },
};
