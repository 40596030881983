
import hasCurrentFirmUuid from '../mixins/hasCurrentFirmUuid';
import casesAPI from '../api/cases';

export default {
  name: 'ClientCaseSelect',

  mixins: [hasCurrentFirmUuid],

  props: {
    client_uuid: {
      type: String | null,
      required: false,
      default: null,
    },
    value: {
      type: String | Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentCaseUuid: {
      type: String,
      required: false,
      default: null,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selectedList: [],
      caseList: [],
      loading: true,
    };
  },

  created() {
    this.grabCases();
  },

  methods: {
    async grabCases() {
      this.loading = true;
      if (this.client_uuid == null) {
        return;
      }
      const rsp = await casesAPI.getClientCases(this.client_uuid);

      if (this.currentCaseUuid) {
        this.caseList = rsp.data.data.filter((item) => item.uuid !== this.currentCaseUuid);
      } else {
        this.caseList = rsp.data.data;
      }

      if (this.value) {
        this.selectedList = this.caseList.filter((item) => {
          if (Array.isArray(this.value)) {
            return this.value.includes(item.uuid);
          } else if (typeof this.value === 'string') {
            return this.value === item.uuid;
          } else if (this.value && typeof this.value === 'object') {
            return this.value.uuid === item.uuid;
          }
        });
      }

      this.loading = false;
    },
  },

  watch: {
    client_uuid(new_val) {
      this.grabCases();
    },

    selectedList(new_val) {
      if (new_val === null) {
        this.$emit('input', null);
        return;
      }
      if (!Array.isArray(new_val)) {
        if (new_val && new_val.uuid !== null) {
          this.$emit('input', new_val.uuid);
        }
        return;
      }

      let reduced = new_val.filter((item) => item && item.uuid !== null).map((item) => item.uuid);

      this.$emit('input', reduced);
    },
  },
};
