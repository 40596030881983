import DOMPurify from 'dompurify';
import Vue from 'vue';

function sanitizeHtml(html) {
  return DOMPurify.sanitize(html);
}

function handleHtml(el, binding) {
  if (binding?.value) {
    el.innerHTML = sanitizeHtml(binding.value);
  } else {
    el.innerHTML = '';
  }
}

Vue.directive('sanitize-html', {
  bind(el, binding) {
      handleHtml(el, binding);
  },

  update(el, binding) {
      handleHtml(el, binding);
  },
});
