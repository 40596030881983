
export default {
  name: 'ErrorBlock',
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    setErrors(data) {
      this.errors = data;
    },
    clear() {
      this.errors = [];
    },
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
};
