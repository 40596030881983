
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'NotificationsItem',

  props: {
    notification: {
      required: true,
      type: Object,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('user', ['isClient']),
    ...mapState('user', ['currentLogin']),

    enableView() {
      return (
        this.notification.data.type !== 'signature.required' && this.notification.model_type !== 'addon_subscription'
      );
    },

    desc() {
      const desc = this.notification.data.description;
      const replaceArr = ['<p>', '</p>'];
      for (const replaceStr of replaceArr) {
        desc.replace(replaceStr, '');
      }
      return desc;
    },
  },

  methods: {
    ...mapActions('notification', ['markAsUnread', 'markAsRead', 'deleteNotification', 'redirectTo']),

    toggleStatus(notification) {
      if (notification.read_at === null) {
        this.markAsRead(notification);
      } else {
        this.markAsUnread(notification);
      }
    },

    deleteItem(notification) {
      this.deleteNotification(notification);
    },

    redirectItem(notification) {
      this.$nuxt.$emit('notificationClicked', notification);
    },
  },
};
