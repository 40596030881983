
import { mapGetters, mapState } from 'vuex';
import conversationsAPI from '@/api/conversations';
import checkForErrors from '@/helpers/checkForErrors';
import casesAPI from '@/api/cases';
import accountAPI from '@/api/account';

export default {
  name: 'ConversationInboxList',
  layout: 'simplified',
  head: {
    title: 'Conversations',
  },

  props: {
    depth: {
      type: String,
      required: true,
    },
    uuid: {
      type: String | null,
      required: false,
      default: null,
    },
    participants: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      toggleView: true,
      conversations: [],
      newConversation: {
        name: null,
        case: null,
        participants: [],
      },
      meta: {},
      params: null,
      archive: false,
      person: null,
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['currentAccountUuid', 'isClient']),

    suggestedParticipants() {
      return this.participants;
    },
  },

  mounted() {
    this.resize();
    window.onresize = this.resize;
  },

  created() {
    this.params = {
      params: {
        include: 'latest_message,participants',
        sort: 'pinned_conversations,latest_message',
      },
    };
    this.grabName();
    this.grabConversations();
  },

  beforeDestroy() {
    this.$nuxt.$off('openModal-createConversation');
  },

  methods: {
    grabName() {
      if (this.depth === 'client') {
        accountAPI.getContact(this.uuid).then((rsp) => {
          this.person = rsp.data.data;
        });
      }
      if (this.depth === 'case') {
        casesAPI.getCase(this.uuid).then((rsp) => {
          this.person = rsp.data.data;
        });
      } else {
        return null;
      }
    },

    filterArchive() {
      this.archive = true;
      this.params = {
        params: {
          include: 'latest_message,participants',
          sort: 'pinned_conversations,latest_message',
          'filter[archived]': 'only',
        },
      };

      let api = null;

      if (this.depth === 'case') {
        api = casesAPI.getCaseConversations(this.uuid, { ...this.params });
      }
      if (['client', 'employee', 'introducer'].includes(this.depth)) {
        api = conversationsAPI.getAccountConversations(this.uuid, { ...this.params });
      }
      if (this.depth === 'firm') {
        api = conversationsAPI.getFirmConversations({ ...this.params });
      }

      if (api === null) {
        return;
      }

      api.then((rsp) => {
        this.conversations = rsp.data.data;
        this.meta = rsp.data.meta;
        this.loading = false;
      });
    },

    filterUnarchive() {
      (this.archive = false),
        (this.params = {
          params: {
            include: 'latest_message,participants',
            sort: 'pinned_conversations,latest_message',
            'filter[archived]': false,
          },
        });

      let api = null;

      if (this.depth === 'case') {
        api = casesAPI.getCaseConversations(this.uuid, { ...this.params });
      }
      if (['client', 'employee', 'introducer'].includes(this.depth)) {
        api = conversationsAPI.getAccountConversations(this.uuid, { ...this.params });
      }
      if (this.depth === 'firm') {
        api = conversationsAPI.getFirmConversations({ ...this.params });
      }

      if (api === null) {
        return;
      }

      api.then((rsp) => {
        this.conversations = rsp.data.data;
        this.meta = rsp.data.meta;
        this.loading = false;
      });
    },
    closeModal() {
      this.$emit('close');
    },

    resize() {
      if (window.innerWidth >= 1000) {
        this.toggleView = true;
      }
      if (window.innerWidth <= 1000) {
        this.toggleView = false;
      }
    },
    openCreateModal() {
      if (!this.isClient) {
        this.newConversation.participants = this.participants;
      }
      this.$refs.createConversationModal.open();
    },

    changePage(url) {
      if (url === null) {
        return;
      }
      this.loaded = false;
      this.$nuxt.$axios.get(url).then((rsp) => {
        this.conversations = rsp.data.data;
        this.meta = rsp.data.meta;
        this.loaded = true;
        window.scrollTo(0, 0);
      });
    },

    grabConversations() {
      this.loading = true;

      let api = null;

      if (this.depth === 'case') {
        api = casesAPI.getCaseConversations(this.uuid, { ...this.params });
      }
      if (['client', 'employee', 'introducer'].includes(this.depth)) {
        api = conversationsAPI.getAccountConversations(this.uuid, { ...this.params });
      }
      if (this.depth === 'firm') {
        api = conversationsAPI.getFirmConversations({ ...this.params });
      }

      if (api === null) {
        return;
      }

      api.then((rsp) => {
        this.conversations = rsp.data.data;
        this.meta = rsp.data.meta;
        this.loading = false;
      });
    },

    createConversation() {
      if (this.depth === 'case') {
        this.newConversation.case = this.uuid;
      } else {
        delete this.newConversation.case;
      }
      if (this.isClient) {
        // push current client to participants
        this.newConversation.participants.push(this.user.current_account.uuid);
      }

      conversationsAPI
        .createConversation(this.newConversation)
        .then((rsp) => {
          this.$refs.create.close();
          this.conversations.unshift(rsp.data.data);
          this.newConversation = {
            name: '',
            participants: [],
          };
          this.$router.push('/conversations/' + rsp.data.data.uuid);
        })
        .catch((err) => {
          checkForErrors.process(err, this.$refs.formErrorPanel);
        });
    },
  },

  watch: {
    requests: {
      handler() {
        this.checkRequest({ name: 'createConversation', callback: this.openCreateModal });
      },
      deep: true,
    },
  },
};
