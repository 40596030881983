
import { mapState, mapGetters } from 'vuex';
import BrandingColoursCSS from '../components/BrandingColoursCSS';
import hasTour from '@/mixins/hasTour';
import { loadScript } from 'vue-plugin-load-script';

export default {
  name: 'App',

  components: { BrandingColoursCSS },

  mixins: [hasTour],

  data() {
    return {
      menuOpen: false,
      showAccountBlock: false,
      showOverlay: false,
      creatingFromTaskable: false,
      task: null,
    };
  },

  computed: {
    ...mapState(['appLoading']),
    ...mapState('user', ['user', 'firm']),
    ...mapGetters('system', ['isInProduction']),
    ...mapGetters('user', ['isClient']),

    isFirmActivated() {
      return this.firm.deactivated_at === null;
    },
  },

  mounted() {
    this.$nuxt.$on('openNoteModal', this.openNote);
    this.$nuxt.$on('openCreateTaskModal', this.openCreateTask);
    this.$nuxt.$on('openConversationModal', this.openConversationModal);
    this.$nuxt.$on('openWelcomeModal', this.openWelcomeModal);
    this.$nuxt.$on('openTimerModal', this.openTimerModal);

    this.loadUpSleek();
  },

  beforeDestroy() {
    this.$nuxt.$off('openNoteModal');
    this.$nuxt.$off('openCreateTaskModal');
    this.$nuxt.$off('openConversationModal');
    this.$nuxt.$off('openWelcomeModal');
    this.$nuxt.$off('openTimerModal');
  },

  methods: {
    loadUpSleek() {
      window.$sleek = [];
      window.SLEEK_PRODUCT_ID = 704318011;
      loadScript('https://client.sleekplan.com/sdk/e.js').then((rsp) => {
        window.$sleek.setUser({
          mail: this.user.email,
          id: this.user.uuid,
          name: this.user.name,
          img: this.user.photo_url,
        });
      });
    },
    openNote() {
      this.$refs.noteModal.open();
    },
    openCreateTask() {
      if (window.location.href.includes('/contacts/')) {
        this.creatingFromTaskable = true;
        this.task = {
          taskable_type: 'client',
        };
      } else {
        this.creatingFromTaskable = false;
        this.task = null;
      }
      this.$refs.createTask.open();
    },
    openConversationModal() {
      this.$refs.conversationModal.open();
    },
    openWelcomeModal() {
      this.$refs.welcomeWidgetModal.open();
    },
    openTimerModal() {
      this.$refs.timerModal.open();
    },

    toggleAccountBlock(e) {
      e.target.scrollIntoView(false);
      this.showAccountBlock = !this.showAccountBlock;
    },

    openMenu() {
      this.menuOpen = true;

      let gsap = this.$gsap;

      this.$nextTick(() => {
        let wrapper = this.$refs.mobileMenuWrapper;
        let menu = this.$refs.mobileMenu;

        this.tl = gsap.timeline({
          onComplete: () => {},
        });

        this.tl.set(menu, { x: -400 }).to(wrapper, { opacity: 1, duration: 0.25 }).to(menu, { x: 0, duration: 0.5 });
      });
    },

    closeMenu() {
      let gsap = this.$gsap;

      if (this.menuOpen) {
        let wrapper = this.$refs.mobileMenuWrapper;
        let menu = this.$refs.mobileMenu;

        this.tl = gsap.timeline({
          onComplete: () => {
            this.menuOpen = false;
          },
        });

        this.tl.to(menu, { x: -400, duration: 0.5 }).to(wrapper, { opacity: 0, duration: 0.25 });
      }
    },

    openSleek() {
      this.showOverlay = true;
      window.$sleek.open();

      window.$sleek.on('close', () => {
        this.showOverlay = false;
      });
    },
  },
  watch: {
    $route(to, from) {
      this.closeMenu();
    },
  },
};
