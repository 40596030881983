export default {
  namespaced: true,

  state: () => ({
    selectedArray: {
      contacts: [],
      tasks: [],
      cases: [],
    },
    totals: {
      pageTotals: {
        contacts: 0,
        tasks: 0,
        cases: 0,
      },
      allTotals: {
        contacts: 0,
        tasks: 0,
        cases: 0,
      }
    },
    bulkActionProgressInfo: {
      uuid: null,
      percentage: 0,
    },
    action: null,
    selectAll: false,
    pageOrAll: null,
    keepSelected: false,
    applyToSubtasks: false
  }),

  actions: {
    setAction({ commit }, action) {
      commit('SET_ACTION', action);
    },
    setSelectedArray({ commit }, selected) {
      commit('SET_SELECTED', selected);
    },
    setSelectedArrayMultiple({ commit }, selected) {
      commit('SET_SELECTED_MULTIPLE', selected);
    },
    setTotals({ commit }, { page, pageTotal, allTotal }) {
      commit('SET_TOTALS', { page, pageTotal, allTotal });
    },
    setKeepSelected({ commit }, keepSelected) {
      commit('SET_KEEP_SELECTED', keepSelected);
    },
    setApplyToSubtasks({ commit }, applyToSubtasks) {
      commit('SET_APPLY_TO_SUBTASKS', applyToSubtasks);
    },
    setSelectAll({ commit }, selectAll) {
      commit('SELECT_ALL', selectAll);
    },
    setPageOrAll({ commit }, pageOrAll) {
      commit('PAGE_OR_ALL', pageOrAll);
    },
    setBulkActionInfo({ commit }, bulkActionInfo) {
      commit('SET_BULK_ACTION_INFO', bulkActionInfo);
    },
    clearState({ commit }, payload = null) {
      commit('CLEAR_STATE', payload);
    },
    clearTotals({ commit }) {
      commit('CLEAR_TOTALS');
    },
  },

  mutations: {
    SET_ACTION(state, action) {
      state.action = action;
    },
    SET_SELECTED(state, payload) {
      if (state.selectedArray[payload.page].includes(payload.uuid)) {
        state.selectedArray[payload.page] = state.selectedArray[payload.page].filter(item => {
          return item !== payload.uuid;
        })
      } else {
        state.selectedArray[payload.page].push(payload.uuid)
      }
    },
    SET_SELECTED_MULTIPLE(state, payload) {
      state.selectedArray[payload.page] = payload.array;
    },
    SET_TOTALS(state, { page, pageTotal, allTotal }) {
      if (state.totals.pageTotals.hasOwnProperty(page) && state.totals.allTotals.hasOwnProperty(page)) {
        state.totals.pageTotals[page] = pageTotal;
        state.totals.allTotals[page] = allTotal;
      }
    },
    SET_KEEP_SELECTED(state, keepSelected) {
      state.keepSelected = keepSelected;
    },
    SET_APPLY_TO_SUBTASKS(state, applyToSubtasks) {
      state.applyToSubtasks = applyToSubtasks;
    },
    SELECT_ALL(state, selectAll) {
      state.selectAll = selectAll;
    },
    PAGE_OR_ALL(state, pageOrAll) {
      state.pageOrAll = pageOrAll;
    },
    SET_BULK_ACTION_INFO(state, bulkActionInfo) {
      state.bulkActionProgressInfo = bulkActionInfo;
    },
    CLEAR_STATE(state, payload) {
      const defaults = {
        selectedArray: { contacts: [], tasks: [], cases: [] },
        action: null,
        selectAll: false,
        pageOrAll: null,
        keepSelected: false,
        applyToSubtasks: false,
        bulkActionProgressInfo: {
          uuid: null,
          percentage: 0,
        }
      };

      if (!payload) {
        Object.assign(state, defaults);
      } else {
        Object.keys(payload).forEach(key => {
          if (key === 'selectedArray' && typeof payload[key] === 'object') {
            Object.keys(payload[key]).forEach(subKey => {
              if (subKey in state.selectedArray) {
                state.selectedArray[subKey] = payload[key][subKey];
              }
            });
          } else if (key in state) {
            state[key] = payload[key];
          }
        });
      }
    },
    CLEAR_TOTALS(state) {
      state.totals = {
        pageTotals: {
          contacts: 0,
          tasks: 0,
          cases: 0,
        },
        allTotals: {
          contacts: 0,
          tasks: 0,
          cases: 0,
        }
      }
    },
  },
}
