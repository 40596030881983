import tasksApi from '@/api/tasks';
import checkFor404 from '@/helpers/checkFor404';

export default {
  namespaced: true,

  state: () => ({
    taskStatuses: [],
    localStatuses: {},
    tasks: [],
    loading: false,
  }),

  getters: {
    getTaskById: (state) => (id) => {
      return state.tasks.find((task) => task.uuid === id);
    },
    getTaskStatusById: (state) => (id) => {
      return state.taskStatuses.find((status) => status.uuid === id);
    },
  },
  mutations: {
    SET_TASKS(state, tasks) {
      state.tasks = tasks;
    },
    CLEAR_TASKS(state) {
      state.tasks = [];
    },
    SET_TASK_STATUSES(state, taskStatuses) {
      state.taskStatuses = taskStatuses;
    },

    SET_LOCAL_TASK_STATUSES(state, localStatuses) {
      state.localStatuses = localStatuses;
    },
    ADD_TASK(state, task) {
      state.tasks.push(task);
    },
    UPDATE_TASK(state, updatedTask) {
      const index = state.tasks.findIndex((task) => task.uuid === updatedTask.uuid);
      if (index !== -1) {
        state.tasks.splice(index, 1, updatedTask);
      }
    },
    DELETE_TASK(state, taskId) {
      state.tasks = state.tasks.filter((task) => task.uuid !== taskId);
    },
    ADD_TASK_STATUS(state, status) {
      state.taskStatuses.push(status);
    },
    UPDATE_TASK_STATUS(state, updatedStatus) {
      const index = state.taskStatuses.findIndex((status) => status.uuid === updatedStatus.uuid);
      if (index !== -1) {
        state.taskStatuses.splice(index, 1, updatedStatus);
      }
    },
    DELETE_TASK_STATUS(state, statusId) {
      state.taskStatuses = state.taskStatuses.filter((status) => status.uuid !== statusId);
    },
    SET_LOADING(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    setLoading({ commit }) {
      commit('SET_LOADING', true);
    },
    unsetLoading({ commit }) {
      commit('SET_LOADING', false);
    },
    createTask({ commit }, task) {
      return tasksApi.createTask(task).then((rsp) => {
        const updatedTask = rsp.data.data;
        commit('ADD_TASK', updatedTask);
      });
    },
    async fetchTask({ commit }, task_uuid) {
      const response = await tasksApi.getTask(task_uuid);
      commit('UPDATE_TASK', response.data.data);
      return response.data.data;
    },
    async updateTask({ commit }, { task_uuid, data }) {
      const response = await tasksApi.updateTask(task_uuid, data);
      commit('UPDATE_TASK', response.data.data);
    },
    async deleteTask({ commit }, task_uuid) {
      await tasksApi.deleteTask(task_uuid);
      commit('DELETE_TASK', task_uuid);
    },
    async completeTask({ commit }, task_uuid) {
      const response = await tasksApi.completeTask(task_uuid);
      commit('UPDATE_TASK', response.data.data);
      return response.data.data;
    },
    async uncompleteTask({ commit }, { task_uuid, task_status_uuid }) {
      const response = await tasksApi.uncompleteTask(task_uuid, task_status_uuid);
      commit('UPDATE_TASK', response.data.data);
    },
    async archiveTask({ commit }, task_uuid) {
      const response = await tasksApi.archiveTask(task_uuid);
      commit('UPDATE_TASK', response.data.data);
      return response.data.data;
    },
    async unarchiveTask({ commit }, { task_uuid, task_status_uuid }) {
      const response = await tasksApi.unarchiveTask(task_uuid, task_status_uuid);
      commit('UPDATE_TASK', response.data.data);
    },
    async fetchTaskStatuses({ commit, dispatch }, data = null) {
      const response = await tasksApi.getAllTaskStatusesFromFirm({ ...data });
      response.sort((a, b) => (a.position > b.position ? 1 : -1));
      commit('SET_TASK_STATUSES', response);
      dispatch('fetchLocalTaskStatuses');
    },
    async fetchLocalTaskStatuses({ commit, state }) {
      const localStatuses = state.taskStatuses.reduce((acc, status) => {
        acc[status.uuid] = { ...status };
        return acc;
      }, {});
      commit('SET_LOCAL_TASK_STATUSES', localStatuses);
    },

    async createTaskStatus({ commit, dispatch }, data) {
      const response = await tasksApi.createATaskStatus(data);
      commit('ADD_TASK_STATUS', response.data.data);
      dispatch('fetchTaskStatuses');
    },

    async fetchTaskStatus({ commit }, task_status_uuid) {
      const response = await tasksApi.getATaskStatus(task_status_uuid);
      commit('UPDATE_TASK_STATUS', response.data.data);
    },
    async updateTaskStatus({ commit, state }, { task_status_uuid, data }) {
      const response = await tasksApi.updateATaskStatus(task_status_uuid, data);
      const updatedStatus = {
        ...state.taskStatuses.find((status) => status.uuid === task_status_uuid),
        ...response.data.data,
      };
      commit('UPDATE_TASK_STATUS', updatedStatus);
    },
    async deleteTaskStatus({ commit }, task_status_uuid) {
      await tasksApi.deleteATaskStatus(task_status_uuid);
      commit('DELETE_TASK_STATUS', task_status_uuid);
    },
  },
};
