export default {
  state: () => ({
    environment: null,
    firmDefaultBrandColours: null,
    ipAllowed: null,
    userIPAddress: null,
    hasCollectedNotifications: false,
    unreadCount: 0,
  }),

  getters: {
    isInProduction(state) {
      return state.environment === 'production';
    },
    isNotInProduction(state) {
      return state.environment !== 'production';
    },
    isIPAllowed(state) {
      return state.ipAllowed;
    },
  },

  actions: {
    setEnvironment({ commit }, environment) {
      commit('SET_ENVIRONMENT', environment);
    },
    setIPAllowed({ commit }, data) {
      commit('SET_IP_ALLOWED', data);
    },
    setUserIPAddress({ commit }, data) {
      commit('SET_USER_IP_ADDRESS', data);
    },
    setFirmDefaultBrandColours({commit}, colours) {
      commit('SET_FIRM_DEFAULT_BRAND_COLOURS', colours);
    },
    setNotificationStatus({ commit }, data) {
      commit('SET_NOTIFICATION_STATUS', data);
    },
    setUnreadCount({ commit }, data) {
      commit('SET_UNREAD_COUNT', data);
    },
  },

  mutations: {
    SET_ENVIRONMENT(state, environment) {
      state.environment = environment;
    },
    SET_IP_ALLOWED(state, data) {
      state.ipAllowed = data;
    },
    SET_USER_IP_ADDRESS(state, data) {
      state.userIPAddress = data;
    },
    SET_FIRM_DEFAULT_BRAND_COLOURS(state, colours) {
      state.firmDefaultBrandColours = colours;
    },
    SET_NOTIFICATION_STATUS(state, data) {
      state.hasCollectedNotifications = data;
    },
    SET_UNREAD_COUNT(state, data) {
      state.unreadCount = data;
    },
  },

  namespaced: true,
};
