export default {
  async redirectWithIntent(intention, intentionData, r, to) {
    await $nuxt.$store.dispatch('intent/addIntent', {intention: intention, data:intentionData});
    r.push(to);
  },
  listen(intention, callback) {
    $nuxt.$on('hasIntent-' + intention, async () => {
      await $nuxt.$store.commit('intent/DELETE_INTENT', intention);
      callback();
    });
  }
}
