
export default {
  name: 'Pagination',
  props: {
    meta: {
      type: Object,
      required: true,
    },
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoHide: {
      type: Boolean,
      default: true,
    }
  },
  computed:{
    showPagination() {
      if (this.autoHide){
          if (this.meta.total > this.meta.per_page){
           return true
         } else {
          return false
         }
      }
      return true
    },
  }
};
