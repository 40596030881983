
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'NavigationNavExpandedSection',

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: true,
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isMember']),

    classes() {
      let urls = [];
      if (this.section.children !== undefined) {
        urls = this.section.children.map((item) => item.to);
      }
      urls.push(this.section.to);

      if (urls.includes(this.$route.path)) {
        return ' border-l-2 border-branding-sidebar-active text-branding-sidebar-active border-branding-sidebar-active bg-branding-sidebar-hover';
      } else {
        return 'text-branding-sidebar-text';
      }
    },

    iconClasses() {
      let urls = [];
      if (this.section.children !== undefined) {
        urls = this.section.children.map((item) => item.to);
      }
      urls.push(this.section.to);
      if (urls.includes(this.$route.path)) {
        return 'text-branding-sidebar-active';
      } else {
        return 'text-branding-sidebar-text';
      }
    },
  },
  methods: {
    menuMouseOver() {
      this.isExpanded = true;
    },
    menuMouseOff() {
      this.isExpanded = false;
    },
  },
};
