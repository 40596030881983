
import { mapState } from 'vuex';
import 'vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css';

// Make VueFullScreenFileDrop run only on client side
// Avoiding "document is not defined" issue
let VueFullScreenFileDrop;
if (process.client) {
  VueFullScreenFileDrop = require('vue-full-screen-file-drop');
}

export default {
  name: 'FileUploadClient',

  components: {
    VueFullScreenFileDrop,
  },

  props: {
    disableFileDrop: {
      type: Boolean,
      required: false,
      default: false,
    },
    bypassWritePermission: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapState('user', ['currentLogin']),

    canUpload() {
      if (this.bypassWritePermission) {
        return true;
      }

      return this.currentLogin.has_write_permission;
    },
  },

  methods: {
    onClick() {
      let uploadFiles = this.$refs.fileUploadClient.files;

      this.sendToFileUploader(uploadFiles);
    },

    onDrop(formData, uploadFiles) {
      if (this.disableFileDrop) {
        return;
      }

      this.sendToFileUploader(uploadFiles);
    },

    sendToFileUploader(uploadFiles) {
      this.$nuxt.$emit('fileUploader', uploadFiles);
    },
  },
};
